import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

export class InvoiceChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [44, 55, 13, 43],
      options: {
        chart: {
          //   width: 380,
          type: "pie",
        },

        colors: ["#556EE5", "#50A5F1", "#34C38F", "#F46969"],
        labels: ["Draft", "Invoice", "Paid", "Unpaid"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                // width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  }
  render() {
    const { item } = this.props;
    const {
      draftInvoiceIds,
      invoicedInvoiceIds,
      paidInvoiceIds,
      unpaidInvoiceIds,
    } = item;
    console.log(item);

    let series = [];
    series.push(draftInvoiceIds?.length ?? 0);
    series.push(invoicedInvoiceIds?.length ?? 0);
    series.push(paidInvoiceIds?.length ?? 0);
    series.push(unpaidInvoiceIds?.length ?? 0);

    return (
      <Card className="mt-2">
        <CardHeader className="bg-transparent border">
          <CardTitle>Invoice</CardTitle>
        </CardHeader>
        <CardBody>
          <ReactApexChart
            options={this.state.options}
            series={series}
            type="pie"
            width={400}
          />
        </CardBody>
      </Card>
    );
  }
}

InvoiceChart.propTypes = {
  item: PropTypes.object,
};

export default InvoiceChart;
