import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Collapse,
  CardHeader,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { getTransports } from "../../store/actions";

import {
  handleDateChange,
  sendOrderDeliveryStatusOptions,
} from "helpers/utils";
import { transportService } from "services/transport-service";
import { timestampFileName } from "helpers/utils";

import moment from "moment";
import SendOrderReportTable from "containers/send-order/SendOrderReportTable";
import NumberFormat from "react-number-format";
import { debounce } from "lodash";

class SendOrderReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
      statuses: null,

      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      moreActionIsOpen: false,
      isOpen: false,
    };
  }

  debouncedFetchData = debounce(term => {
    this.props.getTransports({ term });
  }, 1000);

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  componentDidMount() {
    this.props.getTransports({ page: 1, pageSize: 10, term: "" });
  }

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });

    this.debouncedFetchData(value);
  };

  handleSearch = () => {
    const { term, startDate, endDate, statuses } = this.state;

    this.props.getTransports({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      statuses: statuses.map(a => {
        return a.value;
      }),
    });
  };

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getTransports({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getTransports({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleExportToExcel = () => {
    const { term, startDate, endDate } = this.state;
    transportService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
      },
      `${timestampFileName()}_Send_Order_List.xlsx`
    );
  };

  toggleMoreAction = () => {
    this.setState(prevState => ({
      moreActionIsOpen: !prevState.moreActionIsOpen,
    }));
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleFilterByStatus = ids => {
    this.props.getTransports({ ids });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const {
      draftIds,
      shippingToCambodiaIds,
      inCambodiaWarehouseIds,
      items,
      itemCount,
      pageCount,
      loading,
    } = this.props;
    let all = [];

    all = all
      .concat(draftIds)
      .concat(shippingToCambodiaIds)
      .concat(inCambodiaWarehouseIds);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Send Order Report | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Send Order Report" />
            <Row>
              <Col lg={12}>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={4}>
                        <div className="text-sm-end me-3">
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            outline
                            onClick={this.handleExportToExcel}
                          >
                            <i className="fas fa-file-excel me-1" /> Export to
                            Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={this.toggleBody}
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                          className={
                            this.state.isOpen
                              ? "fas fa-angle-up"
                              : "fas fa-angle-down"
                          }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={this.state.isOpen}>
                    <CardBody className="pb-1">
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <Select
                              name="statuses"
                              value={this.state.statuses}
                              onChange={this.handleSelectChange}
                              options={sendOrderDeliveryStatusOptions}
                              classNamePrefix="select2-selection"
                              isMulti
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              {items && (
                <>
                  <Col md={3}>
                    <Card
                      color="info"
                      className="mini-stats-wid mt-1 mb-2 border clickable"
                      onClick={() => this.handleFilterByStatus(all)}
                    >
                      <CardBody className="p-2">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-white font-size-12 fw-bold">
                              All
                            </p>
                            <h4 className="mb-0 text-white text-center">
                              <NumberFormat
                                value={all.length}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                              />
                            </h4>
                          </div>
                          <div
                            className="mini-stat-icon avatar-sm rounded-circle salign-self-center"
                            style={{
                              borderColor: "white",
                              borderStyle: "solid",
                            }}
                          >
                            <span className="avatar-title bg-transparent">
                              <i className={"fas fa-cubes font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={3}>
                    <Card
                      color="success"
                      className="mini-stats-wid mt-1 mb-2 border clickable"
                      onClick={() => this.handleFilterByStatus(draftIds)}
                    >
                      <CardBody className="p-2">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-white font-size-12 fw-bold">
                              Draft
                            </p>
                            <h4 className="mb-0 text-white text-center">
                              <NumberFormat
                                value={draftIds.length}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                              />
                            </h4>
                          </div>
                          <div
                            className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                            style={{
                              borderColor: "white",
                              borderStyle: "solid",
                            }}
                          >
                            <span className="avatar-title bg-transparent">
                              <i
                                className={"fas fa-truck-loading font-size-24"}
                              />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={3}>
                    <Card
                      color="warning"
                      className="mini-stats-wid mt-1 mb-2 border clickable"
                      onClick={() =>
                        this.handleFilterByStatus(shippingToCambodiaIds)
                      }
                    >
                      <CardBody className="p-2">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-white font-size-12 fw-bold">
                              Shipping To Cambodia
                            </p>
                            <h4 className="mb-0 text-white text-center">
                              <NumberFormat
                                value={shippingToCambodiaIds.length}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                              />
                            </h4>
                          </div>
                          <div
                            className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                            style={{
                              borderColor: "white",
                              borderStyle: "solid",
                            }}
                          >
                            <span className="avatar-title bg-transparent">
                              <i className={"fas fa-ship font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={3}>
                    <Card
                      color="primary"
                      className="mini-stats-wid mt-1 mb-2 border clickable"
                      onClick={() =>
                        this.handleFilterByStatus(inCambodiaWarehouseIds)
                      }
                    >
                      <CardBody className="p-2">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-white font-size-12 fw-bold">
                              In Cambodia Warehouse
                            </p>
                            <h4 className="mb-0 text-white text-center">
                              <NumberFormat
                                value={inCambodiaWarehouseIds.length}
                                displayType="text"
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                              />
                            </h4>
                          </div>
                          <div
                            className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                            style={{
                              borderColor: "white",
                              borderStyle: "solid",
                            }}
                          >
                            <span className="avatar-title bg-transparent">
                              <i className={"fas fa-warehouse font-size-24"} />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="12">
                    <SendOrderReportTable
                      items={items}
                      itemCount={itemCount}
                      currentPage={currentPage}
                      totalPage={pageCount}
                      loading={loading}
                      defaultPageSize={pageSize}
                      onChangePage={this.handleOnPageChange}
                      onPageSizeChange={this.handleOnPageSizeChange}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

SendOrderReport.propTypes = {
  draftIds: PropTypes.array,
  shippingToCambodiaIds: PropTypes.array,
  inCambodiaWarehouseIds: PropTypes.array,
  completedIds: PropTypes.array,
  items: PropTypes.array,
  message: PropTypes.object,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ transport }) => {
  return transport;
};

export default withRouter(
  connect(mapStateToProps, {
    getTransports,
  })(SendOrderReport)
);
