import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";
import role from "./role/reducer";
import unit from "./unit/reducer";
import warehouse from "./warehouse/reducer";
import order from "./order/reducer";
import category from "./category/reducer";
import attribute from "./attribute/reducer";
import user from "./user/reducer";
import orderItem from "./order-item/reducer";
import currency from "./currency/reducer";
import banner from "./banner/reducer";
import itemShippingFee from "./item-shipping-fee/reducer";
import itemShippingFeeEntry from "./item-shipping-fee-entry/reducer";
import userItemShippingFee from "./user-item-shipping-fee/reducer";
import shippingContact from "./shipping-contact/reducer";
import permission from "./permission/reducer";
import orderAttachment from "./order-attachment/reducer";
import orderNote from "./order-note/reducer";
import transport from "./transport/reducer";
import transportOrder from "./transport-order/reducer";
import transportNote from "./transport-note/reducer";
import orderTracking from "./order-tracking/reducer";
import invoice from "./invoice/reducer";
import invoicePaymentHistotry from "./invoice-payment-history/reducer";
import invoicePayment from "./invoice-payment/reducer";
import invoiceNote from "./invoice-note/reducer";
import invoiceReport from "./invoice-report/reducer";
import deliveryAgency from "./delivery-agency/reducer";
import deliverer from "./deliverer/reducer";
import deliveryOrder from "./delivery-order/reducer";
import deliveryOrderItem from "./delivery-order-item/reducer";
import setting from "./settings/reducer";
import orderVerifyTracking from "./order-verify-tracking/reducer";
import orderNotificationHistory from "./order-notification-history/reducer";
import orderMessageHistory from "./order-message-history/reducer";
import deviceInfo from "./device-info/reducer";
import deviceInfoNotificationHistory from "./device-info-notification-history/reducer";
import feedback from "./feedback/reducer";
import alertNotification from "./alert-notification/reducer";
import alertNotificationHistory from "./alert-notification-history/reducer";
import pickup from "./pickup/reducer";
import pickupAttachment from "./pickup-attachment/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  mails,
  crypto,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  role,
  unit,
  warehouse,
  order,
  category,
  attribute,
  user,
  orderItem,
  currency,
  banner,
  itemShippingFee,
  itemShippingFeeEntry,
  userItemShippingFee,
  shippingContact,
  permission,
  orderAttachment,
  orderNote,
  transport,
  transportOrder,
  transportNote,
  orderTracking,
  orderVerifyTracking,
  invoice,
  invoicePaymentHistotry,
  invoicePayment,
  invoiceNote,
  invoiceReport,
  setting,
  deliveryAgency,
  deliverer,
  deliveryOrder,
  deliveryOrderItem,
  orderNotificationHistory,
  orderMessageHistory,
  deviceInfo,
  deviceInfoNotificationHistory,
  feedback,
  alertNotification,
  alertNotificationHistory,
  pickup,
  pickupAttachment,
});

export default rootReducer;
