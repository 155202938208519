import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";

const statusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Pending" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Closed" },
];

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",
      body: "",
      status: null,

      submitted: false,
    };
  }

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
        status: statusOptions.find(e => e.value === item.status),
      });
    } else {
      this.setState({
        id: NIL,
        title: "",
        body: "",
        status: { key: 0, value: 0, label: "Draft" },
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSubmit = () => {
    const { id, title, body, status } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = title && body && status;

    if (isValid) {
      const item = {
        id,
        title,
        body,
        status: status?.value,
      };

      this.props.onSubmit(item);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="">Title</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={submitted && !this.state.title ? "is-invalid" : ""}
                  value={this.state.title}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Title is required.
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="">Content</Label>
                <Input
                  type="textarea"
                  rows="3"
                  name="body"
                  placeholder="Content"
                  value={this.state.body}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <div className="mb-3">
                <div
                  className={
                    "select2-container" +
                    (submitted && !this.state.status ? " is-invalid" : "")
                  }
                >
                  <Label>Status</Label>
                  <Select
                    name="status"
                    value={this.state.status}
                    onChange={this.handleSelectChange}
                    options={statusOptions}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                </div>
                {submitted && !this.state.status && (
                  <div className="invalid-feedback-custom">
                    Status is required.
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
