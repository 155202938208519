import React, { Component } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";

export class InvoiceStatusSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { item, onStatusFilter, onInvoicePaymentStatusFilter } = this.props;
    const {
      invoicedInvoiceIds,
      paidInvoiceIds,
      unpaidInvoiceIds,
      pendingPaymentInvoiceIds,
    } = item;
    let all = [];
    all = all
      .concat(invoicedInvoiceIds)
      .concat(paidInvoiceIds)
      .concat(unpaidInvoiceIds);
    return (
      <Card className="mb-0">
        <CardHeader className="bg-transparent border">
          <CardTitle>Invoice Payment Status Summary</CardTitle>
          <Row>
            <Col md={12}>
              <div className="d-flex flex-row">
                <Card
                  color="primary"
                  className="mini-stats-wid mt-1 mb-2 border me-1 w-100 clickable"
                  onClick={() =>
                    onStatusFilter({
                      invoiceIds: all,
                      paymentStatus: null,
                    })
                  }
                >
                  <CardBody className="p-2">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">All</p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={all.length}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-transparent">
                          <i className={"fas fa-cubes font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>

                <Card
                  color="info"
                  onClick={() =>
                    onStatusFilter({
                      invoiceIds: invoicedInvoiceIds,
                      paymentStatus: { key: 1, value: 1, label: "Invoiced" },
                    })
                  }
                  className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
                >
                  <CardBody className="p-2">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">
                          Invoiced
                        </p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={invoicedInvoiceIds.length}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-transparent">
                          <i className={"fas fa-list font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  color="success"
                  onClick={() =>
                    onStatusFilter({
                      invoiceIds: paidInvoiceIds,
                      paymentStatus: { key: 2, value: 2, label: "Paid" },
                    })
                  }
                  className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
                >
                  <CardBody className="p-2">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">Paid</p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={paidInvoiceIds.length}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle bg-success align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-transparent">
                          <i className={"fas fa-check font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  color="warning"
                  onClick={
                    () =>
                      onInvoicePaymentStatusFilter({ invoicePaymentStatus: 1 }) // 1 is  Pending
                  }
                  className="mini-stats-wid mt-1 mb-2 border ms-1 me-1 w-100 clickable"
                >
                  <CardBody className="p-2">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">
                          Pending Payment
                        </p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={pendingPaymentInvoiceIds.length}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle bg-warning align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-transparent">
                          <i className={"fas fa-check font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card
                  color="danger"
                  onClick={() =>
                    onStatusFilter({
                      invoiceIds: unpaidInvoiceIds,
                      paymentStatus: { key: 3, value: 3, label: "Unpaid" },
                    })
                  }
                  className="mini-stats-wid mt-1 mb-2 border ms-1 w-100 clickable"
                >
                  <CardBody className="p-2">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-white font-size-12 fw-bold">
                          Unpaid
                        </p>
                        <h4 className="mb-0 text-white text-center">
                          <NumberFormat
                            value={unpaidInvoiceIds.length}
                            displayType="text"
                            thousandSeparator={true}
                            fixedDecimalScale={false}
                          />
                        </h4>
                      </div>
                      <div
                        className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                        style={{
                          borderColor: "white",
                          borderStyle: "solid",
                        }}
                      >
                        <span className="avatar-title bg-transparent">
                          <i className={"mdi mdi-close-thick font-size-24"} />
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    );
  }
}

InvoiceStatusSummary.propTypes = {
  item: PropTypes.object,
  onStatusFilter: PropTypes.func,
  onInvoicePaymentStatusFilter: PropTypes.func,
};

export default InvoiceStatusSummary;
