import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Modal, Row, Input, Badge, Spinner } from "reactstrap";

import { Table } from "react-super-responsive-table";
import { getOrdersRequest } from "store/order/saga";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displayDeliveryStatus } from "helpers/utils";
import { getShippingContactRequest } from "store/shipping-contact/saga";
import NumberFormat from "react-number-format";
import { debounce } from "lodash";

export class SelectionListModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemCount: 0,
      pageCount: 0,
      selectedItem: null,
      items: null,

      currentPage: 1,
      pageSize: 5,
      orders: [],
      loading: false,
      isSelected: false,
      isHeaderCheck: false,
    };
  }

  debouncedFetchData = debounce(value => {
    this.populateOrder({ term: value });
  }, 1000);

  populateOrder = params => {
    const { orderParams } = this.props;
    const { deliveryStatus } = orderParams;
    const { term, pageSize, page } = params;

    this.setState({
      loading: true,
    });
    getOrdersRequest({
      page: page ?? 1,
      pageSize: pageSize ?? 5,
      term: term ?? "",
      deliveryStatus,
    }).then(res => {
      const { data, itemCount, pageCount } = res;
      this.setState({
        orders: data.map(a => {
          return {
            ...a,
            isSelect: false,
          };
        }),
        loading: false,
        itemCount,
        pageCount,
      });
    });
  };

  onOpened = () => {
    this.populateOrder({ term: "" });
  };

  handleOnPageSizeChange = size => {
    this.populateOrder({ term: "", page: 1, pageSize: size });
    this.setState({ pageSize: size });
  };

  handleOnChangePage = page => {
    const { pageSize } = this.state;
    this.populateOrder({ pageSize, page });
    this.setState({
      currentPage: page,
    });
  };

  handleFilter = e => {
    const { value } = e.target;
    const { userId } = this.props;
    if (userId) {
      getShippingContactRequest({ userId, term: value ?? "" }).then(res => {
        const { itemCount, pageCount, data } = res;
        this.setState({
          selectedItem: null,
          itemCount,
          pageCount,
          items: data.map(user => {
            return {
              ...user,
              isSelect: false,
            };
          }),
        });
      });
    }
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    const { orders } = this.state;
    let selectedOrderIds = orders
      .filter(e => e.isSelect)
      .map(a => {
        return a.id;
      });

    onSubmit(selectedOrderIds);
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.debouncedFetchData(value);
  };

  handleHeaderCheck = e => {
    const { checked } = e.target;
    this.setState(prevState => ({
      isHeaderCheck: checked,
      isSelected: checked,
      orders: prevState.orders.map(a => {
        return {
          ...a,
          isSelect: checked,
        };
      }),
    }));
  };

  handleRowCheck = (id, e) => {
    const { checked } = e.target;
    const { orders } = this.state;
    let newOrders = orders.map(a => {
      if (a.id === id && checked) {
        return {
          ...a,
          isSelect: true,
        };
      } else if (a.id === id && !checked) {
        return {
          ...a,
          isSelect: false,
        };
      } else {
        return a;
      }
    });

    this.setState({
      isSelected: newOrders.some(a => a.isSelect),
      orders: newOrders,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { itemCount, pageCount, orders, currentPage, pageSize, loading } =
      this.state;
    return (
      <Modal
        size="xl"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    placeholder="Quick Search ..."
                    onChange={this.handleOnSearch}
                  ></Input>
                  <i className="fas fa-search search-icon" />
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <div className="table-responsive" data-pattern="priority-columns">
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap align-middle"
                >
                  <thead>
                    <tr>
                      <th>
                        <Input
                          type="checkbox"
                          checked={this.state.isHeaderCheck}
                          onClick={this.handleHeaderCheck}
                          readOnly
                        />
                      </th>
                      <th>Type</th>
                      <th>Sender</th>
                      <th>Receiver and Shipping</th>
                      <th>Quantity</th>
                      <th>Delivery Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={"7"}>
                          <div className="d-flex justify-content-center">
                            <Spinner color="primary" />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {orders.length > 0 &&
                          orders.map((item, index) => {
                            const { shippingContact, orderAttachments } = item;
                            const { contact, phones, address } =
                              shippingContact;
                            let num =
                              (currentPage - 1) * pageSize + (index + 1);

                            let orderAttachment = null;

                            if (orderAttachments.length > 0) {
                              orderAttachment = orderAttachments[0];
                            }

                            return (
                              <tr key={index}>
                                <td>
                                  <Input
                                    name={`check_${num}`}
                                    onClick={e =>
                                      this.handleRowCheck(item.id, e)
                                    }
                                    checked={item.isSelect}
                                    type="checkbox"
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    {orderAttachment ? (
                                      <div>
                                        <img
                                          src={orderAttachment.url}
                                          width="140"
                                          height={"100"}
                                          style={{ objectFit: "cover" }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="avatar-order">
                                        {item.transportationType === "Sea" ? (
                                          <i className="bx bxs-ship text-primary"></i>
                                        ) : (
                                          <i className="bx bxs-plane text-primary"></i>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p className="mb-1">
                                      <strong>{item.sender}</strong>
                                    </p>
                                    <div className="d-flex">
                                      <div className="right d-flex flex-column">
                                        <p className="text-muted mb-0">ID:</p>
                                        <p className="text-muted mb-0">
                                          Tracking No:
                                        </p>
                                        <p className="text-muted mb-0">
                                          Whs In Date:
                                        </p>
                                        <p className="text-muted mb-0">ETD:</p>
                                        <p
                                          className={
                                            "mb-0 " +
                                            [0, 1, 2, 8].includes(
                                              //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                              item.deliveryStatus
                                                ? "text-danger"
                                                : "text-muted"
                                            )
                                          }
                                        >
                                          ETA:
                                        </p>
                                        {item.originalReference && (
                                          <p className="text-muted mb-0">
                                            Orig Ref:
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className="d-flex flex-column"
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        <p className="text-muted mb-0">
                                          {item.senderUniqueId}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {item.trackingNumber}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {moment(item.createdDate).format(
                                            "DD-MMM-yyyy h:mm:ss a"
                                          )}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {item.etd ? (
                                            <>
                                              {moment(item.etd).format(
                                                "DD-MMM-yyyy"
                                              )}
                                            </>
                                          ) : (
                                            <>N/A</>
                                          )}
                                        </p>
                                        <p
                                          className={
                                            "mb-0 " +
                                            [0, 1, 2, 8].includes(
                                              //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                              item.deliveryStatus
                                                ? "text-danger"
                                                : "text-muted"
                                            )
                                          }
                                        >
                                          {item.eta ? (
                                            <>
                                              {moment(item.eta).format(
                                                "DD-MMM-yyyy"
                                              )}
                                            </>
                                          ) : (
                                            <>N/A</>
                                          )}
                                        </p>
                                        {item.originalReference && (
                                          <p className="text-muted mb-0">
                                            {item.originalReference}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {item.senderUniqueId !==
                                    item.receiverUniqueId ? (
                                      <p className="mb-1">
                                        <strong>{item.receiver}</strong>
                                      </p>
                                    ) : (
                                      <p className="mb-1">
                                        <strong>{contact}</strong>
                                      </p>
                                    )}
                                    <div className="d-flex">
                                      <div className="right d-flex flex-column">
                                        {item.senderUniqueId !==
                                          item.receiverUniqueId && (
                                          <>
                                            <p className="text-muted mb-0">
                                              ID:
                                            </p>
                                            <p className="text-muted mb-0">
                                              Contact:
                                            </p>
                                          </>
                                        )}
                                        <p className="text-muted mb-0">
                                          Phone:
                                        </p>
                                        <p className="text-muted mb-0">
                                          Address:
                                        </p>
                                      </div>
                                      <div
                                        className="d-flex flex-column"
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        {item.senderUniqueId !==
                                          item.receiverUniqueId && (
                                          <>
                                            <p className="text-muted mb-0">
                                              {item.receiverUniqueId}
                                            </p>
                                            <p className="text-muted mb-0">
                                              {contact}
                                            </p>
                                          </>
                                        )}
                                        <p className="text-muted mb-0">
                                          {phones && phones.length > 0 ? (
                                            phones.map((phone, index) => {
                                              return (
                                                <Badge
                                                  color="info"
                                                  className="p-1 me-1"
                                                  key={index}
                                                >
                                                  <NumberFormat
                                                    value={phone.phoneNumber}
                                                    displayType="text"
                                                    format={"### ### ####"}
                                                  />
                                                </Badge>
                                              );
                                            })
                                          ) : (
                                            <Badge
                                              color="info"
                                              className="p-1 me-1"
                                            >
                                              N/A
                                            </Badge>
                                          )}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {address}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {item.quantity} {item.unit}
                                </td>
                                <td>{displayDeliveryStatus(item)}</td>
                              </tr>
                            );
                          })}
                      </>
                    )}
                  </tbody>
                </Table>
              </div>
              <CustomPagination
                itemCount={itemCount}
                currentPage={currentPage}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 15, 20, 25, 30]}
                onChangePage={i => this.handleOnChangePage(i)}
                onPageSizeChange={size => this.handleOnPageSizeChange(size)}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            disabled={!this.state.isSelected}
            onClick={this.handleSubmit}
            type="submit"
          >
            Select
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

SelectionListModal.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  userId: PropTypes.string,
  orderParams: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SelectionListModal;
