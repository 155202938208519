import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Input,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getInvoices, deleteInvoice, clearInvoiceNotify } from "store/actions";
import InvoiceSummary from "containers/invoice/InvoiceSummary";
import InvoiceTable from "containers/invoice/InvoiceTable";
import { timestampFileName, toastMessage } from "helpers/utils";
import { invoiceService } from "services/invoice-service";
import InvoiceStatusSummary from "containers/invoice/InvoiceStatusSummary";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { orderService } from "services/order-service";
import SendNotificationModal from "pages/ReceiveOrder/SendNotificationModal";

export class InvoiceTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      userId: null,
      moreStatusIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      selectedId: null,
      modalNotification: false,
    };
  }

  componentDidMount() {
    const { userId } = this.props;
    this.setState({ userId });
  }

  toggleMoreStatus = () => {
    this.setState(prevState => ({
      moreStatusIsOpen: !prevState.moreStatusIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  handleOnEdit = id => {
    const { userId } = this.state;
    const { history } = this.props;
    history.push(`/invoice/${id}/${userId}`);
  };

  handleStatusFilter = ({ invoiceIds }) => {
    const { userId } = this.state;
    this.props.getInvoices({ userId, invoiceIds });
  };

  handleOnPageChange = page => {
    const { pageSize, userId } = this.state;
    this.props.getInvoices({
      page,
      pageSize,
      userId,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { userId } = this.state;
    this.props.getInvoices({
      page: 1,
      pageSize,
      userId,
    });

    this.setState({
      pageSize,
    });
  };

  handleExportToPdf = id => {
    if (id) {
      invoiceService.exportToPdf(id, `${timestampFileName()}_invoice.pdf`);
    }
  };

  handleOnSearch = e => {
    const { userId } = this.state;
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });
    this.props.getInvoices({ term: value ?? "", userId });
  };

  componentDidUpdate() {
    const { invoice, clearInvoiceNotify } = this.props;
    const { message } = invoice;
    if (message) {
      toastMessage(message);
      clearInvoiceNotify();
    }
  }

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteInvoice(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  handleOnNotificationForPayment = invoice => {
    const { orderId } = invoice;
    this.setState({
      modalNotification: true,
      selectedId: orderId,
    });
  };

  handleSendNotification = data => {
    orderService.informToSenderForInvoicePaymentRequest(data).then(() => {
      this.setState({
        modalNotification: false,
      });
    });
  };

  toggleModalNotification = () => {
    this.setState(prevState => ({
      modalNotification: !prevState.modalNotification,
    }));
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { invoice } = this.props;
    const { items, itemCount, pageCount, loading } = invoice;
    return (
      <>
        <Card className="mb-2 rounded-2">
          <CardHeader className="bg-transparent border pt-3">
            <Row className="g-1">
              <Col md={2}>
                <div className="search-box me-2 mb-2 d-inline-block">
                  <div className="position-relative">
                    <Input
                      type="text"
                      placeholder="Quick Search ..."
                      onChange={this.handleOnSearch}
                    ></Input>
                    <i className="fas fa-search search-icon" />
                  </div>
                </div>
              </Col>
              <Col md={10}></Col>
            </Row>
            <div
              style={{
                position: "absolute",
                top: "0.5rem",
                right: "0.5rem",
                cursor: "pointer",
              }}
              onClick={this.toggleMoreStatus}
            >
              <span className="font-size-24 text-primary ps-2 pe-2">
                <i
                  className={
                    this.state.moreStatusIsOpen
                      ? "fas fa-angle-up"
                      : "fas fa-angle-down"
                  }
                ></i>
              </span>
            </div>
          </CardHeader>
          <Collapse isOpen={this.state.moreStatusIsOpen}>
            {items && (
              <InvoiceStatusSummary
                item={invoice}
                onStatusFilter={this.handleStatusFilter}
              />
            )}
          </Collapse>
        </Card>
        {items && (
          <>
            <InvoiceSummary item={invoice} />
            <InvoiceTable
              items={items}
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={pageCount}
              loading={loading}
              defaultPageSize={pageSize}
              onEdit={this.handleOnEdit}
              onNotificationForPayment={this.handleOnNotificationForPayment}
              onExportToPdf={this.handleExportToPdf}
              onConfirmDelete={this.handleConfirmDelete}
              onChangePage={this.handleOnPageChange}
              onPageSizeChange={this.handleOnPageSizeChange}
            />

            <ConfirmDeleteModal
              title="Confirm Delete"
              isOpen={this.state.modalConfirmDeleteIsOpen}
              toggle={this.toggleModalConfirmDelete}
              onSubmit={this.handleDelete}
            />
          </>
        )}

        {this.state.selectedId && (
          <SendNotificationModal
            orderId={this.state.selectedId}
            isOpen={this.state.modalNotification}
            toggle={this.toggleModalNotification}
            onSubmit={this.handleSendNotification}
          />
        )}
      </>
    );
  }
}

InvoiceTab.propTypes = {
  userId: PropTypes.string,
  invoice: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoices: PropTypes.func,
  deleteInvoice: PropTypes.func,
  clearInvoiceNotify: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ invoice }) => {
  return { invoice };
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoices,
    deleteInvoice,
    clearInvoiceNotify,
  })(InvoiceTab)
);
