import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import {
  getUnits,
  addNewUnit,
  updateUnit,
  getUnitById,
  deleteUnit,
  clearUnitError,
} from "../../../store/unit/actions";
import UnitTable from "./UnitTable";
import ModalForm from "./ModalForm";

class UnitList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getUnits({ page: 1, pageSize: 10, tern: "" });

    // toastr.options = {
    //   timeOut: 5000,
    //   closeButton: true,
    // };
    // toastr.error("Bad Request", "Error");
  }

  handleOnEdit = id => {
    this.props.getUnitById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.props.getUnits({ term: value ?? "" });
  };

  handleSubmit = item => {
    this.props.addNewUnit(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleUpdate = item => {
    this.props.updateUnit(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteUnit(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  componentDidUpdate() {
    const { error } = this.props;

    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");

      this.props.clearUnitError();
    }
  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getUnits({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getUnits({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Unit | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Inventory" breadcrumbItem="Unit List" />
            <Row>
              <Col lg="12">
                {items && (
                  <UnitTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onSearch={this.handleOnSearch}
                    onAddNew={this.handleOnAddNew}
                    onEdit={this.handleOnEdit}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title={"Add New Unit"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit Unit"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

UnitList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getUnits: PropTypes.func,
  addNewUnit: PropTypes.func,
  updateUnit: PropTypes.func,
  getUnitById: PropTypes.func,
  deleteUnit: PropTypes.func,
  clearUnitError: PropTypes.func,
};

const mapStateToProps = ({ unit }) => {
  return unit;
};

export default withRouter(
  connect(mapStateToProps, {
    getUnits,
    addNewUnit,
    updateUnit,
    getUnitById,
    deleteUnit,
    clearUnitError,
  })(UnitList)
);
