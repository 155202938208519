import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import {
  displayInvoicePayments,
  displayInvoicePaymentStatus,
  getDaysLeftBeforeDeadline,
  getDaysOverDeadline,
} from "helpers/utils";
import NumberFormat from "react-number-format";

class InvoiceTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  displayPaymentDay = item => {
    const { statusName } = item;
    let currentDate = new Date();

    let label = "";
    let color = "";

    if (
      item.dueDate &&
      moment.utc(item.dueDate).local().toDate() >= currentDate
    ) {
      label = `${getDaysLeftBeforeDeadline(new Date(item.dueDate))} Days Left`;
      color = "warning";
    } else if (
      item.dueDate &&
      moment.utc(item.dueDate).local().toDate() < currentDate
    ) {
      label = `${getDaysOverDeadline(new Date(item.dueDate))} Days Over`;
      color = "danger";
    }

    return (
      <>
        {statusName === "Paid" ? (
          <Badge className="p-1 ps-2 pe-2" color={"success"}>
            Completed
          </Badge>
        ) : (
          <>
            <Badge className="p-1 ps-2 pe-2" color={color}>
              {label}
            </Badge>
          </>
        )}
      </>
    );
  };

  render() {
    const {
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      items,
      loading,
      onEdit,
      onExportToPdf,
      onConfirmDelete,
      onChangePage,
      onPageSizeChange,
      myInvoice,
      onNotificationForPayment,
    } = this.props;
    return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">
            {loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary m-1" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th style={{ width: "120px" }}>Action</th>
                      <th>Invoice No</th>
                      <th>Tracking No</th>
                      <th>Amount</th>
                      <th>Invoice Date</th>
                      <th>Due Date</th>
                      <th>Payment Day</th>
                      <th>Payment Status</th>
                      <th>Payments</th>
                      <th>Whs In Date</th>
                      <th>Invoice To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      let num =
                        (currentPage - 1) * defaultPageSize + (index + 1);

                      return (
                        <tr
                          key={index}
                          onDoubleClick={() => onEdit(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{num}</td>
                          <td>
                            <div className="text-center">
                              <UncontrolledDropdown>
                                <DropdownToggle className="card-drop" tag="a">
                                  <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-start">
                                  <DropdownItem onClick={() => onEdit(item.id)}>
                                    <i className="mdi mdi-clipboard-list-outline font-size-16 text-primary me-1" />{" "}
                                    Detail
                                  </DropdownItem>
                                  {[1, 3].includes(item.status) && ( // 1 => Invoiced, 3 => Unpaid
                                    <DropdownItem
                                      onClick={() =>
                                        onNotificationForPayment(item)
                                      }
                                    >
                                      <i className="fas fa-file-invoice-dollar font-size-16 text-primary me-1" />{" "}
                                      Send Notification to ask for Payment
                                    </DropdownItem>
                                  )}

                                  <DropdownItem
                                    onClick={() => onExportToPdf(item.id)}
                                  >
                                    <i className="mdi mdi-file-pdf font-size-16 text-primary me-1" />{" "}
                                    Export to PDF
                                  </DropdownItem>

                                  {!myInvoice && (
                                    <DropdownItem
                                      onClick={() => onConfirmDelete(item.id)}
                                    >
                                      <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </td>
                          <td>
                            <Link to={`/invoice/${item.id}`} target="_blank">
                              {item.invoiceNo}
                            </Link>
                          </td>
                          <td>{item.trackingNumber}</td>
                          <td className="text-end">
                            {
                              <NumberFormat
                                value={item.totalAmount}
                                thousandSeparator={true}
                                fixedDecimalScale={false}
                                displayType="text"
                              />
                            }
                          </td>
                          <td>
                            {moment
                              .utc(item.date)
                              .local()
                              .format("DD-MMM-yyyy")}
                          </td>
                          <td>
                            {item.dueDate
                              ? moment
                                  .utc(item.dueDate)
                                  .local()
                                  .format("DD-MMM-yyyy")
                              : null}
                          </td>
                          <td>{this.displayPaymentDay(item)}</td>
                          <td>{displayInvoicePaymentStatus(item)}</td>
                          <td>{displayInvoicePayments(item)}</td>
                          <td>
                            {moment
                              .utc(item.orderDate)
                              .local()
                              .format("DD-MMM-yyyy h:mm:ss a")}
                          </td>
                          <td>
                            {item.invoiceToUser} ({item.invoiceToUserUniqueId})
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <CustomPagination
            itemCount={itemCount}
            currentPage={currentPage}
            totalPage={totalPage}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
            onChangePage={i => onChangePage(i)}
            onPageSizeChange={size => onPageSizeChange(size)}
          />
        </CardBody>
      </Card>
    );
  }
}

InvoiceTable.propTypes = {
  myInvoice: PropTypes.bool,
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onNotificationForPayment: PropTypes.func,
};

export default InvoiceTable;
