import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Badge,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
  displayDeliveryPaymentStatus,
  displayDeliveryStatus,
  displayPaymentStatus,
  displayPickupPaymentStatus,
  displayVerifyStatus,
} from "helpers/utils";

class ReceiveOrderItemTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      items,
      itemCount,
      currentPage,
      totalPage,
      defaultPageSize,
      loading,
      onVerifyOrder,
      onChangePage,
      onPageSizeChange,
      onAddNote,
      onChangeOrderStatus,
      onViewNote,
      onNotificationForPayment,
    } = this.props;
    return (
      <>
        <Card>
          <CardBody>
            <div className="table-rep-plugin">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <div
                  className="table-responsive"
                  data-pattern="priority-columns"
                >
                  <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered table-nowrap align-middle"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Action</th>
                        <th>Type</th>
                        <th>Sender</th>
                        <th>Receiver and Shipping</th>
                        <th>Quantity</th>
                        <th>Payment Status</th>
                        <th>Delivery Status</th>
                        <th>Verify Status</th>
                        <th style={{ width: "200px" }}>Last Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.length > 0 &&
                        items.map((item, index) => {
                          let num =
                            (currentPage - 1) * defaultPageSize + (index + 1);
                          const {
                            shippingContact,
                            orderAttachments,
                            paymentStatus,
                            lastNote,
                          } = item;
                          const { phones, address, contact } = shippingContact;
                          let orderAttachment = null;

                          if (orderAttachments.length > 0) {
                            orderAttachment = orderAttachments[0];
                          }

                          return (
                            <tr key={index}>
                              <td>{num}</td>

                              <td>
                                <div className="text-center">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="card-drop"
                                      tag="a"
                                    >
                                      <i className="mdi mdi-dots-horizontal font-size-18" />
                                    </DropdownToggle>
                                    <DropdownMenu
                                      end={false}
                                      className="text-primary"
                                    >
                                      <DropdownItem
                                        onClick={() => onVerifyOrder(item.id)}
                                      >
                                        <i className="fas fa-check text-success me-1" />{" "}
                                        Verify Order
                                      </DropdownItem>
                                      {[3, 5].includes(item.deliveryStatus) && ( // 3 => In Cambodia Warehouse. 5 => Completed
                                        <DropdownItem
                                          onClick={() =>
                                            onChangeOrderStatus(item)
                                          }
                                        >
                                          <i
                                            className={
                                              (item.deliveryStatus === 3
                                                ? "fas fa-clipboard-check"
                                                : "fas fa-home") +
                                              " text-success me-1"
                                            }
                                          ></i>

                                          {item.deliveryStatus === 3
                                            ? "Change to Complete"
                                            : "Change to In Cambodia Warehouse"}
                                        </DropdownItem>
                                      )}
                                      <DropdownItem divider />
                                      <DropdownItem
                                        onClick={() => onAddNote(item.id)}
                                      >
                                        <i className="fas fa-sticky-note font-size-16 text-primary me-1" />{" "}
                                        Add Note
                                      </DropdownItem>
                                      {lastNote && (
                                        <DropdownItem
                                          onClick={() => onViewNote(item)}
                                        >
                                          <i className="fas fa-book-reader font-size-16 text-primary me-1" />{" "}
                                          View Note
                                        </DropdownItem>
                                      )}

                                      {[1, 3].includes(paymentStatus) && ( // 1 => Invoiced, 3 => Unpaid
                                        <DropdownItem
                                          onClick={() =>
                                            onNotificationForPayment(item.id)
                                          }
                                        >
                                          <i className="fas fa-file-invoice-dollar font-size-16 text-primary me-1" />{" "}
                                          Send Notification to ask for Payment
                                        </DropdownItem>
                                      )}
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex justify-content-center">
                                  {orderAttachment ? (
                                    <div>
                                      <img
                                        src={orderAttachment.url}
                                        width="140"
                                        height={"100"}
                                        style={{ objectFit: "cover" }}
                                      />
                                    </div>
                                  ) : (
                                    <div className="avatar-order">
                                      {item.transportationType === "Sea" ? (
                                        <i className="bx bxs-ship text-primary"></i>
                                      ) : (
                                        <i className="bx bxs-plane text-primary"></i>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </td>

                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <p className="mb-1">
                                    <strong>{item.sender}</strong>
                                  </p>
                                  <div className="d-flex">
                                    <div className="right d-flex flex-column">
                                      <p className="text-muted mb-0">ID:</p>
                                      <p className="text-muted mb-0">
                                        Tracking No:
                                      </p>
                                      <p className="text-muted mb-0">
                                        Whs In Date:
                                      </p>
                                      <p className="text-muted mb-0">ETD:</p>
                                      <p
                                        className={
                                          "mb-0 " +
                                          [0, 1, 2, 8].includes(
                                            //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                            item.deliveryStatus
                                              ? "text-danger"
                                              : "text-muted"
                                          )
                                        }
                                      >
                                        ETA:
                                      </p>
                                      {item.originalReference && (
                                        <p className="text-muted mb-0">
                                          Orig Ref:
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: "1rem" }}
                                    >
                                      <p className="text-muted mb-0">
                                        {item.senderUniqueId}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {item.trackingNumber}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {moment(item.createdDate).format(
                                          "DD-MMM-yyyy h:mm:ss a"
                                        )}
                                      </p>
                                      <p className="text-muted mb-0">
                                        {item.etd ? (
                                          <>
                                            {moment(item.etd).format(
                                              "DD-MMM-yyyy"
                                            )}
                                          </>
                                        ) : (
                                          <>N/A</>
                                        )}
                                      </p>
                                      <p
                                        className={
                                          "mb-0 " +
                                          [0, 1, 2, 8].includes(
                                            //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                            item.deliveryStatus
                                              ? "text-danger"
                                              : "text-muted"
                                          )
                                        }
                                      >
                                        {item.eta ? (
                                          <>
                                            {moment(item.eta).format(
                                              "DD-MMM-yyyy"
                                            )}
                                          </>
                                        ) : (
                                          <>N/A</>
                                        )}
                                      </p>
                                      {item.originalReference && (
                                        <p
                                          className="text-muted text-truncate mb-0"
                                          style={{ width: "120px" }}
                                        >
                                          {item.originalReference}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {item.senderUniqueId !==
                                  item.receiverUniqueId ? (
                                    <p className="mb-1">
                                      <strong>{item.receiver}</strong>
                                    </p>
                                  ) : (
                                    <p className="mb-1">
                                      <strong>{contact}</strong>
                                    </p>
                                  )}
                                  <div className="d-flex">
                                    <div className="right d-flex flex-column">
                                      {item.senderUniqueId !==
                                        item.receiverUniqueId && (
                                        <>
                                          <p className="text-muted mb-0">ID:</p>
                                          <p className="text-muted mb-0">
                                            Contact:
                                          </p>
                                        </>
                                      )}
                                      <p className="text-muted mb-0">Phone:</p>
                                      <p className="text-muted mb-0">
                                        Address:
                                      </p>
                                    </div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: "1rem" }}
                                    >
                                      {item.senderUniqueId !==
                                        item.receiverUniqueId && (
                                        <>
                                          <p className="text-muted mb-0">
                                            {item.receiverUniqueId}
                                          </p>
                                          <p className="text-muted mb-0">
                                            {contact}
                                          </p>
                                        </>
                                      )}
                                      <p className="text-muted mb-0">
                                        {phones && phones.length > 0 ? (
                                          phones.map((phone, index) => {
                                            return (
                                              <Badge
                                                color="info"
                                                className="p-1 me-1"
                                                key={index}
                                              >
                                                <NumberFormat
                                                  value={phone.phoneNumber}
                                                  displayType="text"
                                                  format={"### ### ####"}
                                                />
                                              </Badge>
                                            );
                                          })
                                        ) : (
                                          <Badge
                                            color="info"
                                            className="p-1 me-1"
                                          >
                                            N/A
                                          </Badge>
                                        )}
                                      </p>
                                      <p
                                        className="text-muted mb-0 text-truncate"
                                        style={{ width: "120px" }}
                                      >
                                        {address}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item.quantity} {item.unit}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <div className="right d-flex flex-column">
                                    <p className="text-muted mb-0">
                                      Shipping :
                                    </p>
                                    {item.isPickup && (
                                      <p className="text-muted mb-0">
                                        Pickup :
                                      </p>
                                    )}
                                    {item.isDelivery && (
                                      <p className="text-muted mb-0">
                                        Delvery :
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    className="d-flex flex-column"
                                    style={{ marginLeft: "1rem" }}
                                  >
                                    <p className="text-muted mb-0">
                                      {displayPaymentStatus(item)}
                                    </p>
                                    {item.isPickup && (
                                      <p className="text-muted mb-0">
                                        {displayPickupPaymentStatus(item)}
                                      </p>
                                    )}
                                    {item.isDelivery && (
                                      <p className="text-muted mb-0">
                                        {displayDeliveryPaymentStatus(item)}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td>{displayDeliveryStatus(item)}</td>
                              <td>{displayVerifyStatus(item)}</td>
                              <td>
                                {lastNote && (
                                  <div className="d-flex flex-column">
                                    <p className="text-muted mb-0">
                                      {moment
                                        .utc(lastNote.createdDate)
                                        .local()
                                        .format("DD-MMM-yyyy h:mm:ss a")}
                                    </p>
                                    <p
                                      style={{ width: "140px" }}
                                      className="text-truncate text-muted mb-0"
                                    >
                                      {lastNote.note}
                                    </p>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
            <CustomPagination
              itemCount={itemCount}
              currentPage={currentPage}
              totalPage={totalPage}
              defaultPageSize={defaultPageSize}
              pageSizeOptions={[2, 5, 10, 20, 30, 40, 50, 100]}
              onChangePage={i => onChangePage(i)}
              onPageSizeChange={size => onPageSizeChange(size)}
            />
          </CardBody>
        </Card>
      </>
    );
  }
}

ReceiveOrderItemTable.propTypes = {
  isReceive: PropTypes.bool,
  isDisable: PropTypes.bool,
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onRemove: PropTypes.func,
  onVerifyOrder: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onAddNote: PropTypes.func,
  onChangeOrderStatus: PropTypes.func,
  onViewNote: PropTypes.func,
  onNotificationForPayment: PropTypes.func,
};

export default ReceiveOrderItemTable;
