import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
  Container,
  Button,
  Card,
  Input,
  FormGroup,
  CardHeader,
  Collapse,
  CardBody,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import { getTransports } from "../../store/actions";
import ReceiveOrderTable from "containers/receive-order/ReceiveOrderTable";
import { handleDateChange, receiverDeliveryStatusOptions } from "helpers/utils";
import { transportService } from "services/transport-service";
import { timestampFileName } from "helpers/utils";
import moment from "moment";
import { debounce } from "lodash";

class ReceiveOrderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      term: "",
      startDate: null,
      endDate: null,
      statuses: null,

      modalConfirmDeleteIsOpen: false,
      moreActionIsOpen: false,
      isOpen: false,
    };
  }

  debouncedFetchData = debounce(term => {
    const { statuses } = this.state;
    this.props.getTransports({
      term,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : [
              1, // Delivery To Cambodia
              2, // In Cambodia Warehouse
            ],
    });
  }, 1000);

  toggleBody = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getTransports({
      page: 1,
      pageSize: 10,
      term: "",
      statuses: [
        1, // Delivery To Cambodia
        2, // In Cambodia Warehouse
      ],
    });
  }

  toggleMoreAction = () => {
    this.setState(prevState => ({
      moreActionIsOpen: !prevState.moreActionIsOpen,
    }));
  };

  handleOnEdit = id => {
    const { history } = this.props;
    history.push(`/transport/${id}`);
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.setState({
      term: value ?? "",
    });

    this.debouncedFetchData(value);
  };

  handleSearch = () => {
    const { term, startDate, endDate, statuses } = this.state;

    this.props.getTransports({
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : [
              1, // Delivery To Cambodia
              2, // In Cambodia Warehouse
            ],
    });
  };

  //   componentDidUpdate() {
  //     const { error } = this.props;

  //     if (error) {
  //       toastr.options = {
  //         timeOut: 5000,
  //       };
  //       toastr.error(error, "Error");

  //       this.props.clearTransportError();
  //     }
  //   }

  handleOnPageChange = page => {
    const { pageSize, statuses } = this.state;
    this.props.getTransports({
      page,
      pageSize,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : [
              1, // Delivery To Cambodia
              2, // In Cambodia Warehouse
            ],
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    const { statuses } = this.state;
    this.props.getTransports({
      page: 1,
      pageSize,
      statuses:
        statuses && statuses.length > 0
          ? statuses.map(a => {
              return a.value;
            })
          : [
              1, // Delivery To Cambodia
              2, // In Cambodia Warehouse
            ],
    });

    this.setState({
      pageSize,
    });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/receive-order/${id}`);
  };

  handleExportToExcel = () => {
    const { term, startDate, endDate } = this.state;
    transportService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : "",
        endDate: endDate ? endDate.toISOString() : "",
      },
      `${timestampFileName()}_Order_List.xlsx`
    );
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, itemCount, pageCount, loading } = this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Receive Order List | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Home" breadcrumbItem="Receive Order List" />
            <Row>
              <Col lg={12}>
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={2}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            selectsStart
                            name="startDate"
                            selected={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "startDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Start Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <DatePicker
                            className="form-control"
                            name="endDate"
                            selectsEnd
                            selected={this.state.endDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={date =>
                              handleDateChange(this, date, "endDate")
                            }
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="End Date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          outline
                          color="primary"
                          onClick={this.handleSearch}
                        >
                          <i className="fas fa-filter" /> Filter
                        </Button>
                      </Col>
                      <Col md={4}>
                        <div className="text-sm-end me-3">
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            outline
                            onClick={this.handleExportToExcel}
                          >
                            <i className="fas fa-file-excel me-1" /> Export to
                            Excel
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{
                        position: "absolute",
                        top: "1rem",
                        right: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={this.toggleBody}
                    >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                          className={
                            this.state.isOpen
                              ? "fas fa-angle-up"
                              : "fas fa-angle-down"
                          }
                        ></i>
                      </span>
                    </div>
                  </CardHeader>
                  <Collapse isOpen={this.state.isOpen}>
                    <CardBody className="pb-1">
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <Select
                              name="statuses"
                              value={this.state.statuses}
                              onChange={this.handleSelectChange}
                              options={receiverDeliveryStatusOptions}
                              classNamePrefix="select2-selection"
                              isMulti
                              isClearable
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col lg="12">
                {items && (
                  <ReceiveOrderTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    // onEdit={this.handleOnEdit}
                    onViewDetail={this.handleOnViewDetail}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

ReceiveOrderList.propTypes = {
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getTransports: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ transport }) => {
  return transport;
};

export default withRouter(
  connect(mapStateToProps, {
    getTransports,
  })(ReceiveOrderList)
);
