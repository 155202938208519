import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              {hasAccess(permission.myDashboard.enable) && (
                <li>
                  <Link to="/dashboard">
                    <i className="fas fa-home" />
                    {this.props.t("Dashboard")}
                  </Link>
                </li>
              )}
              {hasAccess(permission.myOrder.enable) && (
                <li>
                  <Link to="/order">
                    <i className="fas fa-shopping-cart" />
                    {this.props.t("Orders")}
                  </Link>
                </li>
              )}
              {hasAccess(permission.myInvoice.enable) && (
                <li>
                  <Link to="/invoice">
                    <i className="fas fa-file-invoice" />
                    {this.props.t("Invoices")}
                  </Link>
                </li>
              )}
              {hasAccess(permission.dashboard.enable) && (
                <li>
                  <Link to="/#">
                    <i className="fas fa-home" />
                    <span>{this.props.t("Dashboards")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/dashboard">{this.props.t("Default")}</Link>
                    </li>
                  </ul>
                </li>
              )}
              {hasAccess(permission.pickup.enable) && (
                <li>
                  <Link to="/pickup">
                    <i className="fas fa-truck-pickup" />
                    <span>{this.props.t("Pickup")}</span>
                  </Link>
                </li>
              )}

              {hasAccess(permission.order.enable) && (
                <li>
                  <Link to="/order">
                    <i className="fas fa-shopping-cart" />
                    <span>{this.props.t("Order")}</span>
                  </Link>
                </li>
              )}
              {(hasAccess(permission.transport.enable) ||
                hasAccess(permission.receiveOrder.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-ship" />
                    <span>{this.props.t("Transport")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.transport.enable) && (
                      <li>
                        <Link to="/transport">
                          {this.props.t("Send Order")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.receiveOrder.enable) && (
                      <li>
                        <Link to="/receive-order">
                          <span>{this.props.t("Receive Order")}</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(hasAccess(permission.deliveryOrder.enable) ||
                hasAccess(permission.deliveryAgency.enable) ||
                hasAccess(permission.deliverer.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-shipping-fast" />
                    <span>{this.props.t("Booking and Delivery")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.deliveryOrder.enable) && (
                      <li>
                        <Link to="/delivery-order">
                          {this.props.t("Delivery Order")}
                        </Link>
                      </li>
                    )}

                    {hasAccess(permission.deliveryAgency.enable) && (
                      <li>
                        <Link to="/delivery-agency">
                          {this.props.t("Delivery Agency")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.deliverer.enable) && (
                      <li>
                        <Link to="/deliverer">{this.props.t("Deliverer")}</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(hasAccess(permission.invoice.enable) ||
                hasAccess(permission.paymentHistory.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-file-invoice" />
                    <span>{this.props.t("Invoices")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.invoice.enable) && (
                      <li>
                        <Link to="/invoice">
                          {this.props.t("Invoice List")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.paymentHistory.enable) && (
                      <li>
                        <Link to="/invoice-payment-history">
                          {this.props.t("Payment History")}
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {(hasAccess(permission.unit.enable) ||
                hasAccess(permission.warehouse.enable) ||
                hasAccess(permission.category.enable) ||
                hasAccess(permission.attribute.enable) ||
                hasAccess(permission.banner.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-tv" />
                    <span>Inventory</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.unit.enable) && (
                      <li>
                        <Link to="/inventory/unit">Unit</Link>
                      </li>
                    )}
                    {hasAccess(permission.warehouse.enable) && (
                      <li>
                        <Link to="/inventory/warehouse">Warehouse</Link>
                      </li>
                    )}
                    {hasAccess(permission.category.enable) && (
                      <li>
                        <Link to="/inventory/category">Category</Link>
                      </li>
                    )}
                    {hasAccess(permission.attribute.enable) && (
                      <li>
                        <Link to="/inventory/attribute">Attribute</Link>
                      </li>
                    )}
                    {hasAccess(permission.banner.enable) && (
                      <li>
                        <Link to="/inventory/banner">Banner</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(hasAccess(permission.user.enable) ||
                hasAccess(permission.role.enable) ||
                hasAccess(permission.permissionEntry.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-users-cog" />
                    <span>{this.props.t("User Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.user.enable) && (
                      <li>
                        <Link to="/user">{this.props.t("User")}</Link>
                      </li>
                    )}
                    {hasAccess(permission.role.enable) && (
                      <li>
                        <Link to="/role">{this.props.t("Role")}</Link>
                      </li>
                    )}
                    {hasAccess(permission.permissionEntry.enable) && (
                      <li>
                        <Link to="/permission">
                          {this.props.t("Permission")}
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(hasAccess(permission.orderReport.enable) ||
                hasAccess(permission.orderVerifyTracking.enable) ||
                hasAccess(permission.sendOrderReport.enable) ||
                hasAccess(permission.receiveOrderReport.enable) ||
                hasAccess(permission.invoiceReport.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="far fa-chart-bar" />
                    <span>{this.props.t("Reports")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.orderReport.enable) && (
                      <li>
                        <Link to="/order-report">
                          {this.props.t("Order Report")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.orderVerifyTracking.enable) && (
                      <li>
                        <Link to="/order-verify-tracking">
                          {this.props.t("Verify Tracking")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.sendOrderReport.enable) && (
                      <li>
                        <Link to="/send-order-report">
                          {this.props.t("Send Order Report")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.receiveOrderReport.enable) && (
                      <li>
                        <Link to="/receive-order-report">
                          {this.props.t("Receive Order Report")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.deliveryOrderReport.enable) && (
                      <li>
                        <Link to="/delivery-order-report">
                          {this.props.t("Delivery Order Report")}
                        </Link>
                      </li>
                    )}
                    {hasAccess(permission.invoiceReport.enable) && (
                      <li>
                        <Link to="/invoice-report">
                          {this.props.t("Invoice Report")}
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {(hasAccess(permission.setting.enable) ||
                hasAccess(permission.currency.enable) ||
                hasAccess(permission.shippingFee.enable) ||
                hasAccess(permission.deviceInfo.enable) ||
                hasAccess(permission.feedback.enable) ||
                hasAccess(permission.alertNotification.enable)) && (
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="fas fa-cogs" />
                    <span>Settings</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {hasAccess(permission.setting.enable) && (
                      <li>
                        <Link to="/setting">General</Link>
                      </li>
                    )}
                    {hasAccess(permission.currency.enable) && (
                      <li>
                        <Link to="/currency">Currency</Link>
                      </li>
                    )}
                    {hasAccess(permission.shippingFee.enable) && (
                      <li>
                        <Link to="/shipping-fee">Shipping Fee</Link>
                      </li>
                    )}

                    {hasAccess(permission.deviceInfo.enable) && (
                      <li>
                        <Link to="/device-info">Device Info</Link>
                      </li>
                    )}
                    {hasAccess(permission.feedback.enable) && (
                      <li>
                        <Link to="/feedback">Feedback</Link>
                      </li>
                    )}
                    {hasAccess(permission.alertNotification.enable) && (
                      <li>
                        <Link to="/alert-notification">Alert Notification</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
