import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { addNewDeliveryOrder } from "store/actions";
import Form from "containers/delivery-order/Form";

export class AddNewDeliveryOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleSubmit = item => {
    const { history } = this.props;
    const payload = {
      history,
      data: item,
    };

    this.props.addNewDeliveryOrder(payload);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/transport");
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Add New Delivery Order | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Home"
              backLink={"/delivery-order"}
              breadcrumbItem="Add New Delivery Order"
            />
            <Form onSubmit={this.handleSubmit} />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AddNewDeliveryOrder.propTypes = {
  history: PropTypes.object,
  addNewDeliveryOrder: PropTypes.func,
};

const mapStateToProps = ({ deliveryOrder }) => {
  return deliveryOrder;
};

export default withRouter(
  connect(mapStateToProps, {
    addNewDeliveryOrder,
  })(AddNewDeliveryOrder)
);
