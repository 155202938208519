import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";

import {
  getTransportOrders,
  addNewTransportOrders,
  deleteTransportOrders,
  clearTransportOrderError,
} from "store/actions";
import CustomPagination from "components/Common/CustomPagination";
import NumberFormat from "react-number-format";
import moment from "moment";
import { debounce } from "lodash";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SelectionListModal from "containers/order/SelectionListModal";
import { exportService } from "services/export-service";
import { timestampFileName } from "helpers/utils";

const TransportOrderTab = ({ transportId, ...props }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [orders, setOrders] = useState([]);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [modalAddOrder, setModalAddOrder] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 1000),
    []
  );

  const {
    getTransportOrders,
    deleteTransportOrders,
    addNewTransportOrders,
    transport,
    transportOrder,
  } = props;
  const { items, itemCount, pageCount, loading } = transportOrder;

  useEffect(() => {
    getTransportOrders({ transportId, page, pageSize, term });
  }, [transportId, term, page, pageSize]);

  useEffect(() => {
    const { item } = transport;
    if (item) {
      setIsDisable([1, 2, 3].some(e => e === item.status)); //1: Shipping To Cambodia, //2 In Cambodia Warehouse. 3 Completed)
    }
  }, [transport.item]);

  useEffect(() => {
    setOrders(
      items.map(a => {
        return {
          ...a,
          isSelect: false,
        };
      })
    );
  }, [items]);

  const handleHeaderCheckChange = e => {
    const { checked } = e.target;
    setOrders(
      orders.map(a => {
        return {
          ...a,
          isSelect: checked,
        };
      })
    );
  };

  const handleRowCheck = (id, e) => {
    const { checked } = e.target;
    setOrders(
      orders.map(a => {
        return a.id === id ? { ...a, isSelect: checked } : a;
      })
    );
  };

  const handleOnRemove = () => {
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    let ids = orders
      .filter(e => e.isSelect)
      .map(a => {
        return a.id;
      });

    deleteTransportOrders({ transportId, orderIds: ids });
    setPage(1);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnSubmit = ids => {
    addNewTransportOrders({ transportId, orderIds: ids });
    setModalAddOrder(false);
    setPage(1);
  };

  const handleExportToExcel = () => {
    const { item } = transport;
    exportService.exportTransportOrdersToExcel(
      { transportId },
      `${timestampFileName()}_${item.title}_Order_List.xlsx`
    );
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <Card className="mb-1 rounded-3">
            <CardHeader className="bg-transparent border pb-0 pt-3">
              <Row className="g-1">
                <Col md={2}>
                  <div className="search-box me-2 mb-2 d-inline-block">
                    <div className="position-relative">
                      <Input
                        type="text"
                        placeholder="Quick Search ..."
                        onChange={e => {
                          const { value } = e.target;
                          debouncedQuickSearch(value);
                        }}
                      ></Input>
                      <i className="fas fa-search search-icon" />
                    </div>
                  </div>
                </Col>
                <Col md={10}>
                  <div className="text-sm-end">
                    {!isDisable ? (
                      <>
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={() => setModalAddOrder(true)}
                        >
                          <i className="fas fa-plus" /> Add Order
                        </Button>
                        <Button
                          type="button"
                          outline
                          disabled={!orders.some(e => e.isSelect)}
                          color="danger"
                          className="me-1"
                          onClick={handleOnRemove}
                        >
                          <i className="fas fa-trash" /> Remove
                        </Button>
                      </>
                    ) : null}
                    <Button
                      type="button"
                      color="primary"
                      outline
                      onClick={handleExportToExcel}
                    >
                      <i className="fas fa-file-excel me-1" /> Export to Excel
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="table-responsive"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered table-nowrap align-middle"
                    >
                      <thead>
                        <tr>
                          <th>
                            <Input
                              type="checkbox"
                              onClick={e => handleHeaderCheckChange(e)}
                              readOnly
                            />
                          </th>

                          <th>#</th>

                          <th>Type</th>
                          <th>Sender</th>
                          <th>Receiver and Shipping</th>
                          <th>Quantity</th>
                          <th>Payment Status</th>
                          <th style={{ width: "200px" }}>Last Note</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.length > 0 &&
                          orders.map((item, index) => {
                            let num = (page - 1) * pageSize + (index + 1);
                            const {
                              shippingContact,
                              orderAttachments,
                              lastNote,
                            } = item;
                            const { phones, address, contact } =
                              shippingContact;
                            let orderAttachment = null;

                            if (orderAttachments.length > 0) {
                              orderAttachment = orderAttachments[0];
                            }

                            return (
                              <tr key={index}>
                                <td>
                                  <Input
                                    name={`check_${num}`}
                                    onClick={e => handleRowCheck(item.id, e)}
                                    checked={item.isSelect}
                                    type="checkbox"
                                    readOnly
                                  />
                                </td>
                                <td>{num}</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    {orderAttachment ? (
                                      <div>
                                        <img
                                          src={orderAttachment.url}
                                          width="140"
                                          height={"100"}
                                          style={{ objectFit: "cover" }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="avatar-order">
                                        {item.transportationType === "Sea" ? (
                                          <i className="bx bxs-ship text-primary"></i>
                                        ) : (
                                          <i className="bx bxs-plane text-primary"></i>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <p className="mb-1">
                                      <strong>{item.sender}</strong>
                                    </p>
                                    <div className="d-flex">
                                      <div className="right d-flex flex-column">
                                        <p className="text-muted mb-0">ID:</p>
                                        <p className="text-muted mb-0">
                                          Tracking No:
                                        </p>
                                        <p className="text-muted mb-0">
                                          Whs In Date:
                                        </p>
                                        <p className="text-muted mb-0">ETD:</p>
                                        <p
                                          className={
                                            "mb-0 " +
                                            [0, 1, 2, 8].includes(
                                              //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                              item.deliveryStatus
                                                ? "text-danger"
                                                : "text-muted"
                                            )
                                          }
                                        >
                                          ETA:
                                        </p>
                                        {item.originalReference && (
                                          <p className="text-muted mb-0">
                                            Orig Ref:
                                          </p>
                                        )}
                                      </div>
                                      <div
                                        className="d-flex flex-column"
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        <p className="text-muted mb-0">
                                          {item.senderUniqueId}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {item.trackingNumber}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {moment(item.createdDate).format(
                                            "DD-MMM-yyyy h:mm:ss a"
                                          )}
                                        </p>
                                        <p className="text-muted mb-0">
                                          {item.etd ? (
                                            <>
                                              {moment(item.etd).format(
                                                "DD-MMM-yyyy"
                                              )}
                                            </>
                                          ) : (
                                            <>N/A</>
                                          )}
                                        </p>
                                        <p
                                          className={
                                            "mb-0 " +
                                            [0, 1, 2, 8].includes(
                                              //0, Draft. 1, In Korea Warehouse. 2, Delivery To Cambodia. 8, Ready for delivery to Cambodia
                                              item.deliveryStatus
                                                ? "text-danger"
                                                : "text-muted"
                                            )
                                          }
                                        >
                                          {item.eta ? (
                                            <>
                                              {moment(item.eta).format(
                                                "DD-MMM-yyyy"
                                              )}
                                            </>
                                          ) : (
                                            <>N/A</>
                                          )}
                                        </p>
                                        {item.originalReference && (
                                          <p
                                            className="text-muted text-truncate mb-0"
                                            style={{ width: "120px" }}
                                          >
                                            {item.originalReference}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {item.senderUniqueId !==
                                    item.receiverUniqueId ? (
                                      <p className="mb-1">
                                        <strong>{item.receiver}</strong>
                                      </p>
                                    ) : (
                                      <p className="mb-1">
                                        <strong>{contact}</strong>
                                      </p>
                                    )}
                                    <div className="d-flex">
                                      <div className="right d-flex flex-column">
                                        {item.senderUniqueId !==
                                          item.receiverUniqueId && (
                                          <>
                                            <p className="text-muted mb-0">
                                              ID:
                                            </p>
                                            <p className="text-muted mb-0">
                                              Contact:
                                            </p>
                                          </>
                                        )}
                                        <p className="text-muted mb-0">
                                          Phone:
                                        </p>
                                        <p className="text-muted mb-0">
                                          Address:
                                        </p>
                                      </div>
                                      <div
                                        className="d-flex flex-column"
                                        style={{ marginLeft: "1rem" }}
                                      >
                                        {item.senderUniqueId !==
                                          item.receiverUniqueId && (
                                          <>
                                            <p className="text-muted mb-0">
                                              {item.receiverUniqueId}
                                            </p>
                                            <p className="text-muted mb-0">
                                              {contact}
                                            </p>
                                          </>
                                        )}
                                        <p className="text-muted mb-0">
                                          {phones && phones.length > 0 ? (
                                            phones.map((phone, index) => {
                                              return (
                                                <Badge
                                                  color="info"
                                                  className="p-1 me-1"
                                                  key={index}
                                                >
                                                  <NumberFormat
                                                    value={phone.phoneNumber}
                                                    displayType="text"
                                                    format={"### ### ####"}
                                                  />
                                                </Badge>
                                              );
                                            })
                                          ) : (
                                            <Badge
                                              color="info"
                                              className="p-1 me-1"
                                            >
                                              N/A
                                            </Badge>
                                          )}
                                        </p>
                                        <p
                                          className="text-muted mb-0 text-truncate"
                                          style={{ width: "120px" }}
                                        >
                                          {address}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {item.quantity} {item.unit}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div className="right d-flex flex-column">
                                      <p className="text-muted mb-0">
                                        Shipping :
                                      </p>
                                      {item.isPickup && (
                                        <p className="text-muted mb-0">
                                          Pickup :
                                        </p>
                                      )}
                                      {item.isDelivery && (
                                        <p className="text-muted mb-0">
                                          Delvery :
                                        </p>
                                      )}
                                    </div>
                                    <div
                                      className="d-flex flex-column"
                                      style={{ marginLeft: "1rem" }}
                                    >
                                      <p className="text-muted mb-0">
                                        {/* {displayPaymentStatus(item)} */}
                                      </p>
                                      {item.isPickup && (
                                        <p className="text-muted mb-0">
                                          {/* {displayPickupPaymentStatus(item)} */}
                                        </p>
                                      )}
                                      {item.isDelivery && (
                                        <p className="text-muted mb-0">
                                          {/* {displayDeliveryPaymentStatus(item)} */}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {lastNote && (
                                    <div className="d-flex flex-column">
                                      <p className="text-muted mb-0">
                                        {moment
                                          .utc(lastNote.createdDate)
                                          .local()
                                          .format("DD-MMM-yyyy h:mm:ss a")}
                                      </p>
                                      <p
                                        style={{ width: "140px" }}
                                        className="text-truncate text-muted mb-0"
                                      >
                                        {lastNote.note}
                                      </p>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </div>
              <CustomPagination
                itemCount={itemCount}
                currentPage={page}
                totalPage={pageCount}
                defaultPageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
                onChangePage={i => setPage(i)}
                onPageSizeChange={size => {
                  setPage(1);
                  setPageSize(size);
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <SelectionListModal
        title={"Order Selection"}
        toggle={() => setModalAddOrder(!modalAddOrder)}
        isOpen={modalAddOrder}
        onSubmit={handleOnSubmit}
        orderParams={{ deliveryStatus: [1] }} // In Korea Warehouse
      />

      <ConfirmDeleteModal
        title="Confirm Delete"
        isOpen={modalConfirmDeleteIsOpen}
        toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
        onSubmit={handleDelete}
      />
    </>
  );
};

TransportOrderTab.propTypes = {
  transport: PropTypes.object,
  transportId: PropTypes.string,
  transportOrder: PropTypes.object,

  getTransportOrders: PropTypes.func,
  deleteTransportOrders: PropTypes.func,
  addNewTransportOrders: PropTypes.func,
};

const mapStateToProps = ({ transport, transportOrder }) => {
  return {
    transport,
    transportOrder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getTransportOrders,
    addNewTransportOrders,
    clearTransportOrderError,
    deleteTransportOrders,
    addNewTransportOrders,
  })(TransportOrderTab)
);
