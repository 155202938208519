import React, { useEffect, useState } from "react";
import { NIL, NIL as NIL_UUID } from "uuid";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Label,
  Input,
  FormGroup,
  CardHeader,
  Alert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import _, { isEmpty, join } from "lodash";
import { getMaxValue, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";
import { userService } from "services/user-service";
import { categoryService } from "services/category-service";
import { currencyService } from "services/currency-service";
import { unitService } from "services/unit-service";
import {
  addNewShippingContactRequest,
  getShippingContactByIdRequest,
  getShippingContactRequest,
} from "store/shipping-contact/saga";
import { itemShippingFeeService } from "services/item-shipping-fee-service";
import OrderAdditionalEntryForm from "./OrderAdditionalEntryForm";
import OrderCBMEntry from "./OrderCBMEntry";
import OrderItemRow from "./OrderItemRow";
import UserSelectionModalList from "pages/Order/UserSelectionModalList";
import SelectionListModal from "containers/shipping-contact/SelectionListModal";
import ModalFormShippingContact from "containers/shipping-contact/ModalFormShippingContact";

const transportationTypeOptions = [
  { key: 1, value: "Sea", label: "Sea" },
  { key: 2, value: "Air", label: "Air" },
];

const deliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Oversea Warehouse" },
  { key: 2, value: 8, label: "Ready for delivery to Cambodia" },
  { key: 3, value: 2, label: "Delivery To Cambodia" },
  { key: 4, value: 3, label: "In Cambodia Warehouse" },
  { key: 5, value: 4, label: "Delivery To Customer" },
  { key: 6, value: 5, label: "Completed" },
  { key: 7, value: 6, label: "Rejected" },
  { key: 8, value: 7, label: "Closed" },
];

const paymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

const typeOptions = [
  { key: 0, value: 0, label: "Box" },
  { key: 1, value: 1, label: "Unit" },
  { key: 2, value: 2, label: "Pallet" },
];

const formatOptionLabel = ({ label, phones, address }) => {
  let phoneArr = phones
    ? phones.map(phone => {
        return phone.phoneNumber;
      })
    : [];
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-1">
        <i className="fas fa-user"></i>: <strong>{label}</strong>
      </span>
      <span className="mb-1">
        <i className="fas fa-phone"></i>: {join(phoneArr, ", ")}
      </span>
      <span className="mb-1">
        <i className="fas fa-address-book"></i>: {address}
      </span>
    </div>
  );
};

const OrderForm = ({ onSubmit, isAddNew, onCancel, order, ...props }) => {
  const [id] = useState(order ? order.id : NIL_UUID);
  const [senderId, setSenderId] = useState(
    order
      ? {
          key: order.senderId,
          value: order.senderId,
          label: order.sender,
        }
      : null
  );
  const [receiverId, setReceiveId] = useState(
    order
      ? {
          key: order.receiverId,
          value: order.receiverId,
          label: order.receiver,
        }
      : null
  );
  const [transportationType, setTransportationType] = useState(
    order
      ? order.transportationType
        ? transportationTypeOptions.find(
            e => e.value === order.transportationType
          )
        : {
            key: 1,
            value: "Sea",
            label: "Sea",
          }
      : {
          key: 1,
          value: "Sea",
          label: "Sea",
        }
  );
  const [deliveryStatus, setDeliveryStatus] = useState(
    order
      ? {
          key: order.deliveryStatus,
          value: order.deliveryStatus,
          label: order.deliveryStatusName,
        }
      : {
          key: 0,
          value: 0,
          label: "Draft",
        }
  );

  const [paymentStatus, setPaymentStatus] = useState(
    order
      ? {
          key: order.paymentStatus,
          value: order.paymentStatus,
          label: order.paymentStatusName,
        }
      : {
          key: 0,
          value: 0,
          label: "Draft",
        }
  );

  const [trackingNumber] = useState(order ? order.trackingNumber : "");
  const [destination] = useState(order ? order.destination : "");
  const [description, setDescription] = useState(
    order ? order.description : ""
  );
  const [price, setPrice] = useState(order ? order.price : 0);
  const [currencyId, setCurrencyId] = useState(
    order
      ? {
          key: order.currencyId,
          value: order.currencyId,
          label: order.currency,
        }
      : null
  );
  const [quantity, setQuantity] = useState(order ? order.quantity : "");
  const [weight, setWeight] = useState(order ? order.weight : "");
  const [weightExtra, setWeightExtra] = useState(
    order ? order.weightExtra : ""
  );
  const [weightComment, setWeightComment] = useState(
    order ? order.weightComment : ""
  );
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [itemPriceFeeAmount] = useState(order ? order.itemPriceFeeAmount : 0.0);
  const [weightFeeAmount, setWeightFeeAmount] = useState(
    order ? order.weightFeeAmount : 0.0
  );
  const [discountPercentage, setDiscountPercentage] = useState(
    order ? order.discountPercentage : ""
  );
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [originalReference, setOriginalReference] = useState(
    order ? order.originalReference : ""
  );
  const [type, setType] = useState(
    order
      ? typeOptions.find(e => e.value === order.type)
      : { key: 0, value: 0, label: "Box" }
  );
  const [orderItems, setOrderItems] = useState(
    order
      ? order.orderItems.map((a, index) => {
          return {
            ...a,
            index,
          };
        })
      : []
  );
  const [orderCBMEntries, setOrderCBMEntries] = useState(
    order
      ? order.orderCBMEntries.map((a, index) => {
          return {
            ...a,
            index,
          };
        })
      : []
  );
  const [unitFee, setUnitFee] = useState(order ? order.unitFee : "");
  const [unitFeeAmount] = useState(order ? order.unitFeeAmount : 0);
  const [isDelivery, setIsDelivery] = useState(
    order ? order.isDelivery : false
  );
  const [isPickup, setIsPickup] = useState(order ? order.isPickup : false);
  const [deliveryFee, setDeliveryFee] = useState(
    order ? order.deliveryFee : ""
  );
  const [pickupFee, setPickupFee] = useState(order ? order.pickupFee : "");
  const [pickupPaymentStatus] = useState(order ? order.pickupPaymentStatus : 0);
  const [deliveryPaymentStatus] = useState(
    order ? order.deliveryPaymentStatus : 0
  );
  const [cbmCurrencyId, setCBMCurrencyId] = useState(
    order
      ? {
          key: order.cbmCurrencyId,
          value: order.cbmCurrencyId,
          label: order.cbmCurrency,
        }
      : null
  );
  const [cbmExchangeRate, setCBMExchangeRate] = useState(
    order ? order.cbmExchangeRate : 0
  );
  const [exchangeRate, setExchangeRate] = useState(
    order ? order.exchangeRate : 0
  );
  const [cbmRate, setCBMRate] = useState(order ? order.cbmRate : 0);
  const [cbmWeight] = useState(order ? order.cbmWeight : null);
  const [weightRate, setWeightRate] = useState(order ? order.weightRate : 0);
  const [createdDate] = useState(
    order
      ? order.createdDate
        ? moment(order.createdDate).toDate()
        : null
      : null
  );
  const [eta] = useState(
    order ? (order.eta ? moment(order.eta).toDate() : null) : null
  );
  const [etd] = useState(
    order ? (order.etd ? moment(order.etd).toDate() : null) : null
  );
  const [unitId, setUnitId] = useState(
    order
      ? {
          key: order.unitId,
          value: order.unitId,
          label: order.unit,
        }
      : null
  );
  const [shippingContactId, setShippingContactId] = useState(
    order
      ? order.shippingContactId
        ? {
            key: order.shippingContactObject.id,
            value: order.shippingContactId,
            label: order.shippingContactObject.contact,
            phones: order.shippingContactObject.phones,
            address: order.shippingContactObject.address,
          }
        : null
      : null
  );
  const [orderAdditionalEntries, setOrderAdditionalEntries] = useState(
    order
      ? order.orderAdditionalEntries && order.orderAdditionalEntries.length > 0
        ? order.orderAdditionalEntries.map((a, index) => {
            return {
              ...a,
              index,
              currencyId: a.currencyId
                ? {
                    key: a.currencyId,
                    value: a.currencyId,
                    label: a.currencySymbol,
                    name: a.currencyLabel,
                  }
                : {},
            };
          })
        : [
            {
              id: NIL,
              index: 0,
              orderId: null,
              currencyId: null,
              title: "",
              amount: "",
            },
          ]
      : [
          {
            id: NIL,
            index: 0,
            orderId: null,
            currencyId: null,
            title: "",
            amount: "",
          },
        ]
  );

  const [senderUsers, setSenderUsers] = useState([]);
  const [receiverUsers, setReceiverUsers] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [shippingAddressOptions, setShippingAddressOptions] = useState([]);
  const [categories, setCategories] = useState(
    order
      ? order.categories.map(category => {
          return {
            key: category.id,
            value: category.id,
            label: category.name,
          };
        })
      : []
  );
  const [attachments] = useState(order ? order.attachments : []);
  const [modalSelectReceiver, setModalSelectReceiver] = useState(false);
  const [modalSelectSender, setModalSelectSender] = useState(false);
  const [modalAddShippingAddress, setModalAddShippingAddress] = useState(false);
  const [modalSelectionShippingAddress, setModalSelectionShippingAddress] =
    useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [totalVolume, setTotalVolume] = useState(0);
  const [totalWeightOrderItem, setTotalWeightOrderItem] = useState(0);
  const [orderItemShippingFee, setOrderItemShippingFee] = useState(0);
  const [orderItemShippingFeeKRW, setOrderItemShippingFeeKRW] = useState(0);
  const [orderCBMEntryShippingFeeKRW, setOrderCBMEntryShippingFeeKRW] =
    useState(0);

  const [orderCBMEntryShippingFee, setOrderCBMEntryShippingFee] = useState(0);
  const [totalOrderAdditionalEntry, setTotalOrderAdditionalEntry] = useState(0);
  const [totalOrderCBMEntryVolume, setTotalOrderCBMEntryVolume] = useState(0);
  const [contact, setContact] = useState("");
  const currencyLabel = "";

  useEffect(() => {
    console.log(order);
    userService.getUsersRequest().then(records => {
      if (records) {
        const users = records.map(user => {
          return {
            key: user.id,
            value: user.id,
            label: `${user.firstName} ${user.lastName} | ${user.uniqueId}`,
          };
        });
        setSenderUsers(users);
        setReceiverUsers(users);
      }
    });

    categoryService.getRequest().then(data => {
      if (data) {
        setCategoryOptions(
          data
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.name,
              };
            })
        );
      }
    });

    currencyService.getRequest().then(data => {
      if (data) {
        setCurrencyOptions(
          data
            .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
            .map(item => {
              return {
                key: item.id,
                value: item.id,
                label: item.symbol,
              };
            })
        );
      }
    });

    unitService.getRequest().then(data => {
      if (data) {
        setUnitOptions(
          data.map(item => {
            return {
              key: item.id,
              value: item.id,
              label: item.name,
            };
          })
        );
      }
    });

    currencyService.getDefaultRequest().then(data => {
      if (data) {
        setCurrencyId({
          key: data.id,
          value: data.id,
          label: data.symbol,
        });
      }
    });

    currencyService.getByQueryRequest({ label: "USD" }).then(data => {
      if (data) {
        setCBMExchangeRate(data.rate);
        setExchangeRate(data.rate);
        setCBMCurrencyId({
          key: data.id,
          value: data.id,
          label: data.symbol,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (receiverId) {
      getShippingContactRequest({
        userId: receiverId.value,
        pageSize: 50,
      }).then(res => {
        const { data } = res;
        setShippingAddressOptions(
          data.map(item => {
            const { id, contact, phones, address } = item;
            return {
              key: id,
              value: id,
              label: contact,
              phones,
              address,
            };
          })
        );
      });
    }
  }, [receiverId]);

  useEffect(() => {
    if (senderId && transportationType) {
      let totalWeight = Number(weight) + Number(weightExtra);
      itemShippingFeeService
        .getByQuery({
          userId: senderId.value,
          transportationType: transportationType?.value ?? "Sea",
          type: 0, // Weight
          itemPrice: Number(totalWeight ?? 0),
        })
        .then(data => {
          //Weight Fee
          let weightFeeAmount = totalWeight * Number(data.value ?? 0);
          setWeightFeeAmount(Number(weightFeeAmount).toFixed(0));
          setWeightRate(data.value);
        });
    }
  }, [weight, weightExtra]);

  useEffect(() => {
    if (senderId && transportationType) {
      let totalWeightOrderItem = _.sumBy(orderItems, a => {
        return Number(a.weight);
      });

      let totalVolume = _.sumBy(orderItems, a => {
        return a.volume;
      });

      itemShippingFeeService
        .getByQuery({
          userId: senderId.value,
          transportationType: transportationType?.value ?? "Sea",
          type: 1, // CBM RATE
          itemPrice: Number(totalVolume ?? 0),
        })
        .then(data => {
          const { value } = data;
          let orderItemShippingFee = Number((totalVolume ?? 0) * value);
          let orderItemShippingFeeKRW =
            cbmExchangeRate * Number(orderItemShippingFee).toFixed(2);
          setOrderItemShippingFee(Number(orderItemShippingFee).toFixed(2));
          setOrderItemShippingFeeKRW(
            Number(orderItemShippingFeeKRW).toFixed(0)
          );
          setCBMRate(value);
          setTotalVolume(totalVolume);
          setTotalWeightOrderItem(totalWeightOrderItem);
        });
    }
  }, [orderItems]);

  useEffect(() => {
    if (senderId && transportationType) {
      let totalOrderCBMEntryVolume = _.sumBy(orderCBMEntries, a => {
        return a.volume;
      });

      itemShippingFeeService
        .getByQuery({
          userId: senderId.value,
          transportationType: transportationType?.value ?? "Sea",
          type: 1, // CBM RATE
          itemPrice: Number(totalOrderCBMEntryVolume ?? 0),
        })
        .then(data => {
          const { value } = data;

          let orderCBMEntryShippingFee = Number(
            (totalOrderCBMEntryVolume ?? 0) * value
          );
          let orderCBMEntryShippingFeeKRW =
            cbmExchangeRate * Number(orderCBMEntryShippingFee).toFixed(2);

          setTotalOrderCBMEntryVolume(totalOrderCBMEntryVolume);
          setOrderCBMEntryShippingFee(
            Number(orderCBMEntryShippingFee).toFixed(2)
          );
          setOrderCBMEntryShippingFeeKRW(
            Number(orderCBMEntryShippingFeeKRW).toFixed(0)
          );
          setCBMRate(value);
        });
    }
  }, [orderCBMEntries]);

  useEffect(() => {
    let totalOrderAdditionalEntry = 0;
    orderAdditionalEntries.forEach(item => {
      const { amount } = item;
      if (item.currencyId) {
        const { name } = item.currencyId;
        if (name === "USD") {
          totalOrderAdditionalEntry += Number(amount) * exchangeRate;
        } else {
          totalOrderAdditionalEntry += Number(amount);
        }
      }
    });

    setTotalOrderAdditionalEntry(totalOrderAdditionalEntry);
  }, [orderAdditionalEntries]);

  useEffect(() => {
    let totalAmount =
      Number(orderItemShippingFeeKRW) +
      Number(orderCBMEntryShippingFeeKRW) +
      Number(weightFeeAmount) +
      Number(unitFee) +
      Number(pickupFee) +
      Number(deliveryFee) +
      totalOrderAdditionalEntry;
    totalAmount = totalAmount - discountAmount;
    console.group("Total Calculation");
    console.log("orderItemShippingFeeKRW:", orderItemShippingFeeKRW);
    console.log("orderCBMEntryShippingFeeKRW:", orderCBMEntryShippingFeeKRW);
    console.log("weightFeeAmount:", weightFeeAmount);
    console.log("unitFee:", unitFee);
    console.log("pickupFee:", pickupFee);
    console.log("deliveryFee:", deliveryFee);
    console.log("totalOrderAdditionalEntry:", totalOrderAdditionalEntry);
    console.log("discountAmount:", discountAmount);
    console.log("Total Amount: ", totalAmount);
    console.groupEnd();
    setTotalAmount(Number(totalAmount).toFixed(0));
  }, [
    orderItemShippingFee,
    orderItemShippingFeeKRW,
    orderCBMEntryShippingFee,
    orderCBMEntryShippingFeeKRW,
    pickupFee,
    unitFee,
    deliveryFee,
    weightFeeAmount,
    totalOrderAdditionalEntry,
  ]);

  useEffect(() => {
    let totalAmount =
      Number(orderItemShippingFeeKRW) +
      Number(orderCBMEntryShippingFeeKRW) +
      Number(weightFeeAmount) +
      Number(unitFee) +
      Number(pickupFee) +
      Number(deliveryFee) +
      totalOrderAdditionalEntry;
    setDiscountAmount(
      Number((totalAmount * discountPercentage ?? 0) / 100).toFixed(0)
    );
  }, [discountPercentage]);

  useEffect(() => {
    let totalAmount =
      Number(orderItemShippingFeeKRW) +
      Number(orderCBMEntryShippingFeeKRW) +
      Number(weightFeeAmount) +
      Number(unitFee) +
      Number(pickupFee) +
      Number(deliveryFee) +
      totalOrderAdditionalEntry;
    totalAmount = totalAmount - discountAmount;
    console.group("Total Calculation 2");
    console.log(
      "orderItemShippingFeeKRW:",
      Number(orderItemShippingFeeKRW).toFixed(0)
    );
    console.log("orderCBMEntryShippingFeeKRW:", orderCBMEntryShippingFeeKRW);
    console.log("weightFeeAmount:", weightFeeAmount);
    console.log("unitFee:", unitFee);
    console.log("pickupFee:", pickupFee);
    console.log("deliveryFee:", deliveryFee);
    console.log("Discount Percentage: ", discountPercentage);
    console.log("totalOrderAdditionalEntry:", totalOrderAdditionalEntry);
    console.log("discountAmount:", discountAmount);
    console.log("Total Amount: ", totalAmount);
    console.groupEnd();
    setTotalAmount(Number(totalAmount).toFixed(0));
  }, [discountAmount]);

  const handleOnAdd = () => {
    let max = 0;

    if (orderAdditionalEntries.length > 0) {
      max = getMaxValue(
        orderAdditionalEntries.map(a => {
          return a.index;
        })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newItem = {
      index: max,
      id: NIL,
      title: "",
      currencyId: null,
      orderId: null,
      amount: 0,
    };

    setOrderAdditionalEntries(orderAdditionalEntries.concat(newItem));
  };

  const handleSubmit = () => {
    setSubmitted(true);

    var isValid =
      senderId &&
      receiverId &&
      shippingContactId &&
      transportationType &&
      currencyId &&
      unitId &&
      type &&
      quantity;

    if (isValid) {
      const data = {
        id,
        senderId: senderId.value,
        receiverId: receiverId.value,
        transportationType: transportationType?.value ?? null,
        deliveryStatus: deliveryStatus.value,
        paymentStatus: paymentStatus.value,
        shippingContactId: shippingContactId?.value ?? null,
        destination: destination,
        trackingNumber: trackingNumber,
        description,
        price: price || 0.0,
        currencyId: currencyId?.value,
        quantity,
        unitId: unitId?.value,
        type: type.value,
        unitFee: unitFee || 0,
        unitFeeAmount,
        weight: weight || 0,
        weightExtra: weightExtra || 0,
        weightComment,
        totalAmount: Number(totalAmount).toFixed(0),
        isDelivery,
        isPickup,
        deliveryFee: deliveryFee || 0,
        pickupFee: pickupFee || 0,
        pickupPaymentStatus,
        deliveryPaymentStatus,
        itemPriceFeeAmount,
        weightFeeAmount,
        discountPercentage: discountPercentage || 0,
        discountAmount,
        originalReference,
        attachments,
        exchangeRate,
        cbmCurrencyId: cbmCurrencyId?.value ?? null,
        cbmExchangeRate,
        cbmRate,
        cbmWeight,
        weightRate,
        categories: categories.map(category => {
          return {
            id: category.value,
            name: category.label,
          };
        }),
        orderItems,
        orderCBMEntries,
        eta: eta ? moment(eta).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
        etd: etd ? moment(etd).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
        orderAdditionalEntries:
          orderAdditionalEntries &&
          orderAdditionalEntries
            .filter(e => isValidOrderAdditionalEntry(e))
            .map((a, index) => {
              return {
                id: a.id,
                index,
                orderId: id,
                currencyId: a.currencyId?.value,
                title: a.title,
                amount: a.amount || 0,
              };
            }),
      };

      onSubmit(data);
    }
  };

  const isValidOrderAdditionalEntry = item => {
    return item.currencyId && item.title && item.amount;
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleAddNewOrderItem = () => {
    let max = 0;

    if (orderItems.length > 0) {
      max = getMaxValue(
        orderItems.map(a => {
          return a.index;
        })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newOrderItem = {
      id: NIL_UUID,
      index: max,
      title: "",
      orderId: id,
      displayOrder: max,
      quantity: "",
      length: "",
      width: "",
      height: "",
      weight: "",
      volume: "",
    };

    setOrderItems(orderItems.concat(newOrderItem));
  };

  const handleOnRemoveOrderItem = item => {
    let newOrderItems = orderItems.filter(a => a.index !== item.index);
    setOrderItems(newOrderItems);
  };

  const handleOnOrderItemChange = orderItem => {
    setOrderItems(
      orderItems.map(a => {
        return a.index === orderItem.index ? orderItem : a;
      })
    );
  };

  const handleCategorySelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      const newItem = newValue.find(e => e.__isNew__ === true);
      const { value } = newItem;
      const category = {
        name: value,
      };

      categoryService.createRequest(category).then(id => {
        if (id) {
          categoryService.getByIdRequest(id).then(data => {
            if (data) {
              const newOption = {
                key: data.id,
                value: data.id,
                label: data.name,
              };

              setCategories(categories.concat(newOption));
              setCategoryOptions(categoryOptions.concat(newOption));
            }
          });
        }
      });
    } else {
      setCategories(newValue);
    }
  };

  const handleAddNewOrderCBMEntry = () => {
    let max = 0;

    if (orderCBMEntries.length > 0) {
      max = getMaxValue(
        orderCBMEntries.map(a => {
          return a.index;
        })
      );
      max += 1;
    } else {
      max = max + 1;
    }

    const newOrderCBMEntries = {
      id: NIL_UUID,
      index: max,
      orderId: id,
      cargoCode: "",
      commodity: "",
      quantityPackage: "",
      quantityCatton: "",
      weight: 0,
      volume: 0,
    };

    setOrderCBMEntries(orderCBMEntries.concat(newOrderCBMEntries));
  };

  const handleOnRemoveOrderCBMEntry = orderCBMEntry => {
    setOrderCBMEntries(
      orderCBMEntries.filter(a => a.index !== orderCBMEntry.index)
    );
  };

  const handleOnChangeOrderCBMEntry = orderCBMEntry => {
    setOrderCBMEntries(
      orderCBMEntries.map(a => {
        return a.index === orderCBMEntry.index ? orderCBMEntry : a;
      })
    );
  };

  const handleOnRemove = item => {
    setOrderAdditionalEntries(
      orderAdditionalEntries.filter(e => e.index !== item.index)
    );
  };

  const handleOnChange = item => {
    setOrderAdditionalEntries(
      orderAdditionalEntries.map(a => {
        return a.index === item.index ? item : a;
      })
    );
  };

  const handleSelectSenderUser = user => {
    setModalSelectSender(false);
    setSenderId({
      key: user.id,
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    });
  };

  const handleSelectReceiverUser = user => {
    populateShippingAddress(user.id);
    setModalSelectReceiver(false);
    setReceiveId({
      key: user.id,
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    });
  };

  const populateShippingAddress = userId => {
    getShippingContactRequest({ userId, pageSize: 50 }).then(res => {
      const { data } = res;
      setShippingAddressOptions(
        data.map(item => {
          const { id, contact, phones, address } = item;
          return {
            key: id,
            value: id,
            label: contact,
            phones,
            address,
          };
        })
      );
    });
  };

  const handleSubmitSelectionShippingAddress = item => {
    if (item) {
      setModalSelectionShippingAddress(false);
      setShippingContactId({
        key: item.id,
        value: item.id,
        label: item.contact,
        address: item.address,
      });
    }
  };

  const hanldeSubmitShippingAddress = shippingAddress => {
    addNewShippingContactRequest(shippingAddress)
      .then(id => {
        const { userId } = shippingAddress;
        getShippingContactByIdRequest(id).then(data => {
          if (data) {
            populateShippingAddress(userId);
            setModalAddShippingAddress(false);
            setShippingContactId({
              key: data.id,
              value: data.id,
              label: data.contact,
              phones: data.phones,
              address: data.address,
            });
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleShippingAddressSelectChange = (newValue, actionMeta) => {
    const { action } = actionMeta;
    if (action === "create-option") {
      setModalAddShippingAddress(true);
      setContact(newValue.value);
    } else {
      setShippingContactId(newValue);
    }
  };
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card className="mb-3">
            {totalAmount == 0 && id !== NIL_UUID ? (
              <Alert color="warning">
                <strong>Warning!</strong>
                <br />
                Please check calculation! Total Amount this order is zero.
              </Alert>
            ) : null}

            <CardBody className="pt-3">
              <CardTitle>Order</CardTitle>
              <Row>
                <Col md={4}>
                  <div className="mb-2">
                    <Label>Sender</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (submitted && !senderId ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <Select
                          name="senderId"
                          value={senderId}
                          onChange={option => setSenderId(option)}
                          options={senderUsers}
                          classNamePrefix="select2-selection"
                          isClearable
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={() => setModalSelectSender(true)}
                        outline
                      >
                        <i className="bx bx-search-alt"></i> Find
                      </Button>
                    </FormGroup>
                    {submitted && !senderId && (
                      <div className="invalid-feedback-custom">
                        Sender is required.
                      </div>
                    )}
                  </div>
                  <FormGroup
                    className={
                      "select2-container" +
                      (submitted && !transportationType ? " is-invalid" : "")
                    }
                  >
                    <Label>Transportation Type</Label>
                    <Select
                      name="transportationType"
                      value={transportationType}
                      onChange={value => setTransportationType(value)}
                      options={transportationTypeOptions}
                      classNamePrefix="select2-selection"
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !transportationType && (
                    <div className="invalid-feedback-custom">
                      Transportation Type is required.
                    </div>
                  )}
                  <FormGroup
                    className={
                      "select2-container" +
                      (submitted && !deliveryStatus ? " is-invalid" : "")
                    }
                  >
                    <Label>Delivery Status</Label>
                    <Select
                      name="deliveryStatus"
                      value={deliveryStatus}
                      onChange={value => setDeliveryStatus(value)}
                      options={deliveryStatusOptions}
                      classNamePrefix="select2-selection"
                      // isDisabled
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !deliveryStatus && (
                    <div className="invalid-feedback-custom">
                      Delivery Status is required.
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div className="mb-2">
                    <Label>Receiver</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (submitted && !receiverId ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <Select
                          name="receiverId"
                          value={receiverId}
                          onChange={value => setReceiveId(value)}
                          options={receiverUsers}
                          classNamePrefix="select2-selection"
                          isClearable
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={() => setModalSelectReceiver(true)}
                        outline
                      >
                        <i className="bx bx-search-alt"></i> Find
                      </Button>
                    </FormGroup>
                    {submitted && !receiverId && (
                      <div className="invalid-feedback-custom">
                        Receiver is required.
                      </div>
                    )}
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Tracking Number</Label>
                    <Input
                      type="text"
                      name="trackingNumber"
                      readOnly
                      value={trackingNumber}
                    />
                  </FormGroup>
                  <FormGroup
                    className={
                      "select2-container" +
                      (submitted && !paymentStatus ? " is-invalid" : "")
                    }
                  >
                    <Label>Shipping Payment Status</Label>
                    <Select
                      name="paymentStatus"
                      value={paymentStatus}
                      onChange={value => setPaymentStatus(value)}
                      options={paymentStatusOptions}
                      classNamePrefix="select2-selection"
                      isDisabled
                      isClearable
                    />
                  </FormGroup>
                  {submitted && !paymentStatus && (
                    <div className="invalid-feedback-custom">
                      Payment Status is required.
                    </div>
                  )}
                </Col>
                <Col md={4}>
                  <div className="mb-2">
                    <Label>Shipping Contact</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (submitted && !shippingContactId ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <CreatableSelect
                          name="shippingContactId"
                          value={shippingContactId}
                          onChange={handleShippingAddressSelectChange}
                          options={shippingAddressOptions}
                          className="custom-shipping-addr"
                          classNamePrefix="select2-selection"
                          formatOptionLabel={formatOptionLabel}
                          isDisabled={!receiverId}
                          isClearable
                        />
                      </div>
                      <Button
                        color="primary"
                        onClick={() => setModalSelectionShippingAddress(true)}
                        outline
                        disabled={!receiverId}
                      >
                        <i className="bx bx-search-alt"></i> Find
                      </Button>
                    </FormGroup>
                    {submitted && !shippingContactId && (
                      <div className="invalid-feedback-custom">
                        Shipping Contact is required.
                      </div>
                    )}
                  </div>

                  <FormGroup className="mb-3">
                    <Label htmlFor="originalReference">
                      Original Reference
                    </Label>
                    <Input
                      type="textarea"
                      name="originalReference"
                      rows={3}
                      placeholder="Original Reference"
                      value={originalReference}
                      onChange={e => {
                        const { value } = e.target;
                        setOriginalReference(value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>Dates</CardTitle>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label htmlFor="createdDate">Whs in Date</Label>
                    <DatePicker
                      id="createdDate"
                      name="createdDate"
                      className={"form-control"}
                      selected={createdDate}
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Whs in Date"
                      readOnly={true}
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label htmlFor="etd">
                      Estimate Time of Departure (ETD)
                    </Label>
                    <DatePicker
                      className="form-control"
                      name="etd"
                      selected={etd}
                      readOnly={true}
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Estimate Time of Departure (ETD)"
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label htmlFor="eta">Estimate Time of Arrival (ETA)</Label>
                    <DatePicker
                      className="form-control"
                      name="eta"
                      selected={eta}
                      readOnly={true}
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Estimate Time of Arrival (ETA)"
                      isClearable
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>Order Detail</CardTitle>
              <Row>
                <Col md={4}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Description</Label>
                    <Input
                      type="text"
                      name="description"
                      placeholder="Description"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="select2-container">
                    <Label>Category</Label>
                    <CreatableSelect
                      name="categories"
                      value={categories}
                      isMulti={true}
                      onChange={handleCategorySelectChange}
                      options={categoryOptions}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <div
                      className={
                        "select2-container" +
                        (submitted && !type ? " is-invalid" : "")
                      }
                    >
                      <Label>Type</Label>
                      <Select
                        name="type"
                        value={type}
                        onChange={value => setType(value)}
                        options={typeOptions}
                        classNamePrefix="select2-selection"
                        isClearable
                      />
                    </div>
                    {submitted && !type && (
                      <div className="invalid-feedback-custom">
                        Type is required.
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Item Price</Label>
                    <NumberFormat
                      name="price"
                      value={price}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      placeholder="0"
                      prefix={`${currencyLabel} `}
                      onValueChange={values => {
                        const { value } = values;
                        setPrice(value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <div
                      className={
                        "select2-container" +
                        (submitted && !currencyId ? " is-invalid" : "")
                      }
                    >
                      <Label>Currency</Label>
                      <Select
                        name="currencyId"
                        value={currencyId}
                        onChange={value => setCurrencyId(value)}
                        options={currencyOptions}
                        classNamePrefix="test select2-selection"
                        isClearable
                        isDisabled
                      />
                    </div>
                    {submitted && !currencyId && (
                      <div className="invalid-feedback-custom">
                        Currency is required.
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-3">
                    <div className={submitted && !quantity ? "is-invalid" : ""}>
                      <Label>Quantity</Label>
                      <NumberFormat
                        name="quantity"
                        value={quantity}
                        className={
                          "form-control text-end" +
                          (submitted && !quantity ? " is-invalid" : "")
                        }
                        placeholder="0"
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        onValueChange={values => {
                          const { value } = values;
                          setQuantity(value);
                        }}
                      />
                    </div>

                    {submitted && !quantity && (
                      <div className="invalid-feedback-custom">
                        Quantity is required.
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <div className="mb-3">
                    <div
                      className={
                        "select2-container" +
                        (submitted && !unitId ? " is-invalid" : "")
                      }
                    >
                      <Label>Unit</Label>
                      <Select
                        name="unitId"
                        value={unitId}
                        onChange={value => setUnitId(value)}
                        options={unitOptions}
                        classNamePrefix="select2-selection"
                        isClearable
                      />
                    </div>
                    {submitted && !unitId && (
                      <div className="invalid-feedback-custom">
                        Unit is required.
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col md={12}>
          <Card>
            <CardHeader className="bg-transparent border pb-1">
              <div className="d-flex justify-content-between">
                <CardTitle>CBM Item Detail</CardTitle>
                <span
                  className="px-2"
                  style={{ cursor: "pointer" }}
                  onClick={handleAddNewOrderItem}
                >
                  <i className="fas fa-plus text-primary" />
                </span>
              </div>
            </CardHeader>
            <CardBody>
              {orderItems.map((item, index) => {
                return (
                  <OrderItemRow
                    key={index}
                    isFirst={index === 0}
                    item={item}
                    onChange={handleOnOrderItemChange}
                    onRemove={handleOnRemoveOrderItem}
                  />
                );
              })}
              {orderItems.length > 0 && (
                <Row className="mb-2">
                  <Col md={6}></Col>
                  <Col md={6} className="pe-5">
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Total Volume</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="totalVolume"
                          value={Number(totalVolume).toFixed(4)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Total Weight</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="totalWeightOrderItem"
                          value={Number(totalWeightOrderItem).toFixed(2)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">CBM Rate (USD)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="cbmRates"
                          value={Number(cbmRate).toFixed(2)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Shipping Fee (USD)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="orderItemShippingFee"
                          value={Number(orderItemShippingFee).toFixed(2)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Exchange Rate (KRW)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="cbmExchangeRate"
                          value={cbmExchangeRate}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Shipping Fee (KRW)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="orderItemShippingFeeKRW"
                          value={Number(orderItemShippingFeeKRW).toFixed(0)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardHeader className="bg-transparent border pb-1">
              <div className="d-flex justify-content-between">
                <CardTitle>CBM Item Detail From Shipper</CardTitle>
                <span
                  className="px-2"
                  style={{ cursor: "pointer" }}
                  onClick={handleAddNewOrderCBMEntry}
                >
                  <i className="fas fa-plus text-primary" />
                </span>
              </div>
            </CardHeader>
            <CardBody>
              {orderCBMEntries.map((item, index) => {
                return (
                  <OrderCBMEntry
                    item={item}
                    isFirst={index === 0}
                    key={index}
                    onChange={handleOnChangeOrderCBMEntry}
                    onRemove={handleOnRemoveOrderCBMEntry}
                  />
                );
              })}
              {orderCBMEntries.length > 0 && (
                <Row className="mb-2">
                  <Col md={3} className="pe-1"></Col>
                  <Col md={3} className="px-1"></Col>
                  <Col md={6} className="pe-5">
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Total Volume</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="totalOrderCBMEntryVolume"
                          value={Number(totalOrderCBMEntryVolume).toFixed(4)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">CBM Rate (USD)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="cbmRates"
                          value={Number(cbmRate).toFixed(2)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Shipping Fee (USD)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="orderCBMEntryShippingFee"
                          value={Number(orderCBMEntryShippingFee).toFixed(2)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Exchange Rate (KRW)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="cbmExchangeRate"
                          value={cbmExchangeRate}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col md={9} className="px-1">
                        <div
                          className="d-flex flex-column align-items-end"
                          style={{ width: "100%", paddingRight: "10px" }}
                        >
                          <Label className="pt-2">Shipping Fee (KRW)</Label>
                        </div>
                      </Col>
                      <Col md={3} className="px-1">
                        <NumberFormat
                          name="orderCBMEntryShippingFeeKRW"
                          value={Number(orderCBMEntryShippingFeeKRW).toFixed(0)}
                          className="form-control text-end"
                          thousandSeparator={true}
                          fixedDecimalScale={false}
                          readOnly
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md={9}>
          <Card>
            <CardBody>
              <CardTitle>Weight and Fee</CardTitle>
              <Row>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Actual Weight (kg)</Label>
                    <NumberFormat
                      name="weight"
                      value={weight}
                      className="form-control text-end"
                      placeholder="0"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      onValueChange={values => {
                        const { value } = values;
                        setWeight(value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Weight Extra (kg)</Label>
                    <NumberFormat
                      name="weightExtra"
                      value={weightExtra}
                      className="form-control text-end"
                      placeholder="0"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      onValueChange={values => {
                        const { value } = values;
                        setWeightExtra(value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Weight Total (kg)</Label>
                    <Input
                      type="number"
                      name="weight"
                      className="text-end"
                      placeholder="Weight"
                      value={Number(weight ?? 0) + Number(weightExtra ?? 0)}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Comment</Label>
                    <Input
                      type="text"
                      name="weightComment"
                      placeholder="Weight Comment"
                      value={weightComment}
                      onChange={e => {
                        const { value } = e.target;
                        setWeightComment(value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3">
                    <Label>Rate</Label>
                    <NumberFormat
                      name="weightFeeAmount"
                      readOnly
                      value={Number(weightRate).toFixed(0)}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-3">
                    <Label>Amount</Label>
                    <NumberFormat
                      name="weightFeeAmount"
                      readOnly
                      value={weightFeeAmount}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Unit Fee</CardTitle>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3" style={{ flexGrow: "1" }}>
                    <Label>Unit Fee (KRW)</Label>

                    <NumberFormat
                      name="unitFee"
                      value={unitFee}
                      className="form-control text-end"
                      placeholder="0"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      onValueChange={values => {
                        const { value } = values;
                        setUnitFee(value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <CardTitle>Additional Services</CardTitle>
              {orderAdditionalEntries &&
                orderAdditionalEntries.map((a, index) => {
                  return (
                    <OrderAdditionalEntryForm
                      key={index}
                      item={a}
                      isPrimary={index === 0}
                      onAdd={handleOnAdd}
                      onRemove={handleOnRemove}
                      onChange={handleOnChange}
                    />
                  );
                })}
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Pickup</CardTitle>
              <Row>
                <Col md={12}>
                  <div className="form-check form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheckcolor1"
                      checked={isPickup}
                      onChange={e => {
                        setIsPickup(e.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                    >
                      Is Pickup ?
                    </label>
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Pickup Fee (KRW)</Label>
                    <NumberFormat
                      name="pickupFee"
                      readOnly={!isPickup}
                      value={pickupFee}
                      className="form-control text-end"
                      placeholder="0"
                      onValueChange={obj => {
                        const { value } = obj;
                        setPickupFee(value);
                      }}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Delivery</CardTitle>
              <Row>
                <Col md={12}>
                  <div className="form-check form-check-primary mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isDelivery"
                      checked={isDelivery}
                      onChange={e => setIsDelivery(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="customCheckcolor1"
                    >
                      Is Delivery ?
                    </label>
                  </div>
                  <FormGroup className="mb-3">
                    <Label>Delivery Fee (KRW)</Label>
                    <NumberFormat
                      name="deliveryFee"
                      readOnly={!isDelivery}
                      value={deliveryFee}
                      className="form-control text-end"
                      placeholder="0"
                      onValueChange={obj => {
                        const { value } = obj;
                        setDeliveryFee(value);
                      }}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      prefix={`${currencyLabel} `}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Discount</CardTitle>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <Label>Percentage</Label>
                    <NumberFormat
                      name="discountPercentage"
                      value={discountPercentage}
                      className="form-control text-end"
                      fixedDecimalScale={false}
                      placeholder="% 0"
                      onValueChange={obj => {
                        const { value } = obj;
                        setDiscountPercentage(value);
                      }}
                      prefix={`% `}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Amount</Label>
                    <NumberFormat
                      name="discountAmount"
                      value={discountAmount}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      readOnly
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle>Total Fee</CardTitle>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <Label>Total Amount (KRW)</Label>
                    <NumberFormat
                      name="totalAmount"
                      readOnly
                      value={Number(totalAmount).toFixed(0)}
                      className="form-control text-end"
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="float-end">
                {hasAccess(permission.order.write) && (
                  <Button color="primary" onClick={handleSubmit}>
                    Submit{" "}
                  </Button>
                )}{" "}
                <Button color="primary" onClick={handleCancel} outline>
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <UserSelectionModalList
        title={"Find Sender"}
        isOpen={modalSelectSender}
        toggle={() => setModalSelectSender(!modalSelectSender)}
        onSubmit={handleSelectSenderUser}
      />

      <UserSelectionModalList
        title={"Find Reciever"}
        isOpen={modalSelectReceiver}
        toggle={() => setModalSelectReceiver(!modalSelectReceiver)}
        onSubmit={handleSelectReceiverUser}
      />

      <SelectionListModal
        title={"Find Shipping Contact"}
        userId={receiverId?.value ?? null}
        isOpen={modalSelectionShippingAddress}
        toggle={() =>
          setModalSelectionShippingAddress(!modalSelectionShippingAddress)
        }
        onSubmit={handleSubmitSelectionShippingAddress}
      />

      <ModalFormShippingContact
        title={"Add New Shipping Contact"}
        contact={contact}
        userId={receiverId?.value ?? null}
        isOpen={modalAddShippingAddress}
        toggle={() => setModalAddShippingAddress(!modalAddShippingAddress)}
        onSubmit={hanldeSubmitShippingAddress}
      />
    </>
  );
};

OrderForm.propTypes = {
  id: PropTypes.string,
  order: PropTypes.object,
  orderItem: PropTypes.object,
  isAddNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default OrderForm;
