import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Button, Card, CardHeader, Container, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import {
  getRoles,
  addNewRole,
  updateRole,
  getRoleById,
  deleteRole,
  clearRoleError,
  clearRoleNotify,
} from "../../../store/role/actions";
import RoleTable from "./RoleTable";
import ModalForm from "./ModalForm";
import { toastMessage } from "helpers/utils";
import { debounce } from "lodash";

class RoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
    };
  }

  debouncedFetchData = debounce(term => {
    this.props.getRoles({ term });
  }, 1000);

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getRoles({ page: 1, pageSize: 10, term: "" });
  }

  handleOnEdit = id => {
    this.props.getRoleById(id);
    this.setState({
      id,
      modalEditIsOpen: true,
    });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.debouncedFetchData(value);
  };

  handleSubmit = item => {
    this.props.addNewRole(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleUpdate = item => {
    const payload = {
      data: item,
      permissionTab: false,
    };
    this.props.updateRole(payload);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteRole(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  componentDidUpdate() {
    const { message, clearRoleNotify } = this.props;
    if (message) {
      toastMessage(message);
      clearRoleNotify();
    }
  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getRoles({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getRoles({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/role/${id}`);
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Role | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="User Management" breadcrumbItem="Role List" />
            <Row>
              <Col lg="12">
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={8}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={this.handleOnAddNew}
                          >
                            <i className="fas fa-plus" /> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>

              <Col lg="12">
                {items && (
                  <RoleTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={this.handleOnEdit}
                    onViewDetail={this.handleOnViewDetail}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title={"Add New Role"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit Role"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

RoleList.propTypes = {
  message: PropTypes.object,
  history: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getRoles: PropTypes.func,
  addNewRole: PropTypes.func,
  updateRole: PropTypes.func,
  getRoleById: PropTypes.func,
  deleteRole: PropTypes.func,
  clearRoleError: PropTypes.func,
  clearRoleNotify: PropTypes.func,
};

const mapStateToProps = ({ role }) => {
  return role;
};

export default withRouter(
  connect(mapStateToProps, {
    getRoles,
    addNewRole,
    updateRole,
    getRoleById,
    deleteRole,
    clearRoleError,
    clearRoleNotify,
  })(RoleList)
);
