import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Button, Card, CardHeader, Container, Input } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getUsers,
  updateUser,
  getUserById,
  clearUserNotify,
  resetUserPassword,
  registerUserPortal,
  deleteUserById,
} from "../../../store/user/actions";
import UserTable from "./UserTable";
import ModalForm from "containers/user/ModalForm";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import ResetPasswordModalForm from "containers/user/ResetPasswordModalForm";
import { SEVERITY, toastMessage } from "helpers/utils";
import SendNotificationModalForm from "containers/user/SendNotificationModalForm";
import { userService } from "services/user-service";
import { debounce } from "lodash";

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
      modalNotification: false,
      modalResetPwd: false,
    };
  }

  debouncedFetchData = debounce(term => {
    this.props.getUsers({ term });
  }, 1000);

  toggleResetPwd = () => {
    this.setState(prevState => ({
      modalResetPwd: !prevState.modalResetPwd,
    }));
  };

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  toggleModalNotification = () => {
    this.setState(prevState => ({
      modalNotification: !prevState.modalNotification,
    }));
  };

  componentDidMount() {
    this.props.getUsers({ page: 1, pageSize: 10, term: "" });
  }

  handleOnEdit = id => {
    const { history } = this.props;
    history.push(`/user/${id}`);
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.debouncedFetchData(value);
  };

  handleUpdate = item => {
    this.props.updateUser(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  componentDidUpdate() {
    const { message, clearUserNotify } = this.props;

    if (message) {
      toastMessage(message);
      clearUserNotify();
    }
  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getUsers({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getUsers({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  handleOnViewDetail = id => {
    const { history } = this.props;
    history.push(`/user/${id}`);
  };

  handleResetPassword = userId => {
    this.setState({
      id: userId,
      modalResetPwd: true,
    });
    this.props.getUserById(userId);
  };

  handleSubmitResetPwd = data => {
    const { resetUserPassword } = this.props;
    resetUserPassword(data);
    this.setState({
      modalResetPwd: false,
    });
  };

  handleOnAddNew = () => {
    this.setState({
      modalAddIsOpen: true,
    });
  };

  handleSubmit = data => {
    this.props.registerUserPortal(data);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deleteUserById({ id });
    this.setState({
      modalConfirmDeleteIsOpen: false,
    });
  };

  handleOnSendNotification = id => {
    this.setState({
      id,
      modalNotification: true,
    });
  };

  handleSubmitSendNotification = data => {
    userService
      .sendNotificationRequest(data)
      .then(() => {
        toastMessage({
          title: "Send Notification",
          text: `Notification has been sent successfully.`,
          severity: SEVERITY.SUCCESS,
        });
        this.setState({ modalNotification: false });
      })
      .catch(err => {
        const error =
          err?.response?.data?.message ?? "Something has gone wrong.";
        toastMessage({
          title: "Send Notification",
          text: `${error}`,
          severity: SEVERITY.DANGER,
        });

        this.setState({ modalNotification: false });
      });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>User | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="User Management" breadcrumbItem="User List" />
            <Row>
              <Col lg="12">
                <Card className="mb-1 rounded-3">
                  <CardHeader className="bg-transparent border pb-0 pt-3">
                    <Row className="g-1">
                      <Col md={4}>
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              type="text"
                              placeholder="Quick Search ..."
                              onChange={this.handleOnSearch}
                            ></Input>
                            <i className="fas fa-search search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="primary"
                            className="me-1"
                            onClick={this.handleOnAddNew}
                          >
                            <i className="fas fa-plus" /> Add New
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                {items && (
                  <UserTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onSearch={this.handleOnSearch}
                    onViewDetail={this.handleOnViewDetail}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                    onResetPassword={this.handleResetPassword}
                    onConfirmDelete={this.handleConfirmDelete}
                    onSendNotification={this.handleOnSendNotification}
                  />
                )}

                <ModalForm
                  title={"Add New User"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit User"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
                {item && (
                  <ResetPasswordModalForm
                    item={item}
                    isOpen={this.state.modalResetPwd}
                    toggle={this.toggleResetPwd}
                    onSubmit={this.handleSubmitResetPwd}
                  />
                )}

                {this.state.id && (
                  <SendNotificationModalForm
                    title="Send Notification"
                    userId={this.state.id}
                    isOpen={this.state.modalNotification}
                    toggle={this.toggleModalNotification}
                    onSubmit={this.handleSubmitSendNotification}
                  />
                )}
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

UserList.propTypes = {
  history: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  message: PropTypes.object,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getUsers: PropTypes.func,
  updateUser: PropTypes.func,
  resetUserPassword: PropTypes.func,
  getUserById: PropTypes.func,
  clearUserNotify: PropTypes.func,
  registerUserPortal: PropTypes.func,
  deleteUserById: PropTypes.func,
};

const mapStateToProps = ({ user }) => {
  return user;
};

export default withRouter(
  connect(mapStateToProps, {
    getUsers,
    updateUser,
    getUserById,
    clearUserNotify,
    resetUserPassword,
    registerUserPortal,
    deleteUserById,
  })(UserList)
);
