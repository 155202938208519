import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Badge, Button, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardText,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";
// import TransportOrderTab from "./TransportOrderTab";
// import TransportNoteTab from "./TransportNoteTab";

import {
  getDeliveryOrderById,
  updateDeliveryOrder,
  updateOrder,
  getOrderById,
  updateOrderAttachment,
  deleteOrderAttachment,
  getOrderAttachments,
  getDeliveryOrderItems,
  getTransportById,
  updateTransport,
  getTransportNote,
  getAlertNotificationHistory,
} from "store/actions";
import AlertNotificationHistoryTab from "./AlertNotificationHistoryTab";

export class AlertNotificationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id !== NIL) {
      this.setState({
        id,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { match } = props;
    const { id } = match.params;
    if (id !== state.id) {
      return id;
    }
    return null;
  }

  handleUpdateAttachment = attachments => {
    const payload = {
      id: this.state.id,
      attachments,
    };

    this.props.updateOrderAttachment(payload);
  };

  handlePopulateAttachment = () => {
    const { id } = this.state;
    this.props.getOrderAttachments({ orderId: id });
  };

  handleSubmit = order => {
    const { updateOrder, history } = this.props;
    const payload = {
      history,
      data: order,
    };
    updateOrder(payload);
  };

  handleCancel = () => {
    const { history } = this.props;
    history.push("/delivery-order");
  };

  handleOnBackToList = () => {
    const { history } = this.props;
    history.push("/delivery-order");
  };

  handlePopulateDeliveryOrderItem = () => {
    const { id } = this.state;
    const { getAlertNotificationHistory } = this.props;
    getAlertNotificationHistory({ alertNotificationId: id });
  };

  handlePopulateDeliveryOrder = () => {
    const { id } = this.state;
    const { getDeliveryOrderById } = this.props;
    getDeliveryOrderById(id);
  };

  handlePopulateTransportNote = () => {
    const { id } = this.state;
    this.props.getTransportNote({ transportId: id });
  };

  handleOnSubmit = data => {
    this.props.updateDeliveryOrder(data);
  };

  render() {
    const { deliveryOrder, deliveryOrderItem } = this.props;
    const { item, loading } = deliveryOrder;

    let itemCount = 0;

    if (deliveryOrderItem) {
      itemCount = deliveryOrderItem?.itemCount ?? 0;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Alert Notification Detail | Retail Logistics</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Home"
              backLink={"/alert-notification"}
              breadcrumbItem="Alert Notification Detail"
            />
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                        this.handlePopulateDeliveryOrderItem();
                      }}
                    >
                      History
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    {this.state.id !== NIL && (
                      <AlertNotificationHistoryTab
                        alertNotificationId={this.state.id}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AlertNotificationDetail.propTypes = {
  deliveryOrder: PropTypes.object,
  deliveryOrderItem: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  getDeliveryOrderById: PropTypes.func,
  getOrderById: PropTypes.func,
  updateDeliveryOrder: PropTypes.func,

  updateOrder: PropTypes.func,
  updateOrderAttachment: PropTypes.func,
  deleteOrderAttachment: PropTypes.func,
  getOrderAttachments: PropTypes.func,
  getDeliveryOrderItems: PropTypes.func,
  getTransportById: PropTypes.func,
  updateTransport: PropTypes.func,
  getTransportNote: PropTypes.func,
  getAlertNotificationHistory: PropTypes.func,
};

const mapStateToProps = ({ deliveryOrder, deliveryOrderItem }) => {
  return {
    deliveryOrder,
    deliveryOrderItem,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryOrderById,
    updateDeliveryOrder,

    updateOrder,
    getOrderById,
    updateOrderAttachment,
    deleteOrderAttachment,
    getOrderAttachments,
    getDeliveryOrderItems,
    getTransportById,
    updateTransport,
    getTransportNote,
    getAlertNotificationHistory,
  })(AlertNotificationDetail)
);
