import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_TRANSPORTS,
  ADD_NEW_TRANSPORT,
  GET_TRANSPORT_BY_ID,
  UPDATE_TRANSPORT,
  DELETE_TRANSPORT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getTransportsSuccess,
  getTransportByIdSuccess,
  getTransportsFail,
  addNewTransportSuccess,
  addNewTransportError,
  updateTransportSuccess,
  deleteTransportSuccess,
  deleteTransportError,
} from "./actions";

// Transport
const getTransportsRequest = query => post("/api/Transport/paging", query);
const addNewTransportRequest = role => post("/api/Transport", role);
const getTransportByIdRequest = id => get(`/api/Transport/${id}`);
const updateTransportRequest = role => update("/api/Transport", role);
const deleteTransportRequest = id => del(`/api/Transport/${id}`);

function* fetchTransports({ payload }) {
  try {
    const response = yield call(getTransportsRequest, payload);
    yield put(getTransportsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportsFail(error));
  }
}

function* addNewTransport({ payload }) {
  try {
    const { history, data } = payload;
    let response = yield call(addNewTransportRequest, data);
    yield put(addNewTransportSuccess(response));
    history.push(`/transport/${response}`);
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(addNewTransportError(error));
  }
}

function* updateTransport({ payload }) {
  try {
    let response = yield call(updateTransportRequest, payload);
    // response = yield call(getTransportByIdRequest, response);
    // yield put(getTransportByIdSuccess(response));
    yield put(updateTransportSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportsFail(error));
    yield put(getTransportsFail(error));
  }
}

function* getTransportById({ payload }) {
  try {
    var response = yield call(getTransportByIdRequest, payload);
    yield put(getTransportByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getTransportsFail(error));
  }
}

function* deleteTransport({ payload }) {
  try {
    yield call(deleteTransportRequest, payload);
    const response = yield call(getTransportsRequest, {});
    yield put(getTransportsSuccess(response));
    yield put(deleteTransportSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(deleteTransportError(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_TRANSPORTS, fetchTransports);
  yield takeEvery(ADD_NEW_TRANSPORT, addNewTransport);
  yield takeEvery(GET_TRANSPORT_BY_ID, getTransportById);
  yield takeEvery(UPDATE_TRANSPORT, updateTransport);
  yield takeEvery(DELETE_TRANSPORT, deleteTransport);
}

export default roleSaga;
