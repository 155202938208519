import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MetaTags } from "react-meta-tags";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getPickup,
  getPickupById,
  clearPickupNotify,
  getApprovePickupById,
  getRejectPickupById,
} from "store/actions";
import PickupTable from "containers/pickup/PickupTable";
import moment from "moment";
import { debounce } from "lodash";
import PickupModalForm from "containers/pickup/PickupModalForm";
import { toastMessage } from "helpers/utils";
import PickupConfirmModalForm from "containers/pickup/PickupConfirmModalForm";

const PickupList = props => {
  const [selectedId, setSelectedId] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [isExpand, setIsExpand] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false);
  const [confirmType, setConfirmType] = useState("");

  const {
    getPickup,
    getPickupById,
    clearPickupNotify,
    getApprovePickupById,
    getRejectPickupById,
    pickup,
    history,
  } = props;
  const { items, item, message, itemCount, pageCount, loading } = pickup;

  useEffect(() => {
    getPickup({ page, pageSize, term, notStatuses: [0] });
  }, [getPickup, page, pageSize, term]);

  useEffect(() => {
    if (message) {
      toastMessage(message);
      clearPickupNotify();
    }
  }, [message]);

  const debouncedFetchData = useCallback(
    debounce(term => {
      setTerm(term);
    }, 1000),
    []
  );

  const handleSearch = () => {
    getPickup({
      page,
      pageSize,
      term,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
    });
  };

  const toggleExpand = () => {
    setIsExpand(!isExpand);
  };

  const handleOnPageChange = page => {
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPage(1);
    setPageSize(pageSize);
  };

  const handleOnViewDetail = id => {
    getPickupById(id);
    setModalIsOpen(true);
  };

  const handleApprove = data => {
    getApprovePickupById(data);
    setModalIsOpen(false);
  };

  const handleReject = data => {
    getRejectPickupById(data);
    setModalIsOpen(false);
  };

  const handleSubmit = data => {
    const { confirmType, id, comment } = data;
    const {
      contactName,
      phone,
      address,
      shippingContactName,
      shippingContactPhone,
      shippingContactAddress,
      note,
    } = item;
    switch (confirmType) {
      case "Approve":
        getApprovePickupById({
          id: id,
          contactName,
          phone,
          address,
          shippingContactName,
          shippingContactPhone,
          shippingContactAddress,
          note,
          comment,
        });
        break;
      case "Reject":
        getRejectPickupById({
          id,
          contactName,
          phone,
          address,
          shippingContactName,
          shippingContactPhone,
          shippingContactAddress,
          note,
          comment,
        });
        break;
      default:
        break;
    }
    setModalConfirmIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pickup List | Retail Logistics</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Pickup List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-2">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={e => debouncedFetchData(e.target.value)}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ReactDatePicker
                          className="form-control"
                          selectsStart
                          name="startDate"
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Start Date"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ReactDatePicker
                          className="form-control"
                          name="endDate"
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={date => setEndDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="End Date"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        type="button"
                        outline
                        color="primary"
                        className="d-lg-none w-100"
                        onClick={handleSearch}
                      >
                        <i className="fas fa-filter" /> Filter
                      </Button>

                      <Button
                        type="button"
                        outline
                        color="primary"
                        className="d-none d-lg-block"
                        onClick={handleSearch}
                      >
                        <i className="fas fa-filter" /> Filter
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col md={11}>
                          <div className="text-sm-end">
                            {/* <Button
                              type="button"
                              color="primary"
                              outline
                              size="md"
                              onClick={handleExportToExcel}
                            >
                              <i className="fas fa-file-excel" /> Export to
                              Excel
                            </Button> */}
                          </div>
                        </Col>
                        <Col md={1}>
                          <div onClick={toggleExpand}>
                            <span className="font-size-24 text-primary me-2">
                              <i
                                className={
                                  isExpand
                                    ? "fas fa-angle-up"
                                    : "fas fa-angle-down"
                                }
                              ></i>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={isExpand}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col lg="12">
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div
                    className="spinner-border text-primary m-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {items && (
                    <PickupTable
                      items={items}
                      itemCount={itemCount}
                      totalPage={pageCount}
                      page={page}
                      pageSize={pageSize}
                      onChangePage={handleOnPageChange}
                      onPageSizeChange={handleOnPageSizeChange}
                      onViewDetail={handleOnViewDetail}
                      onApprove={id => {
                        setSelectedId(id);
                        getPickupById(id);
                        setConfirmType("Approve");
                        setModalConfirmIsOpen(true);
                      }}
                      onReject={id => {
                        setSelectedId(id);
                        getPickupById(id);
                        setConfirmType("Reject");
                        setModalConfirmIsOpen(true);
                      }}
                    />
                  )}
                </>
              )}

              {item && (
                <PickupModalForm
                  title="Pickup Details"
                  item={item}
                  isOpen={modalIsOpen}
                  toggle={() => setModalIsOpen(!modalIsOpen)}
                  onApprove={handleApprove}
                  onReject={handleReject}
                />
              )}

              <PickupConfirmModalForm
                id={selectedId}
                isOpen={modalConfirmIsOpen}
                confirmType={confirmType}
                toggle={() => setModalConfirmIsOpen(!modalConfirmIsOpen)}
                onSubmit={handleSubmit}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

PickupList.propTypes = {
  message: PropTypes.object,
  pickup: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,

  getPickup: PropTypes.func,
  getPickupById: PropTypes.func,
  clearPickupNotify: PropTypes.func,
  getApprovePickupById: PropTypes.func,
  getRejectPickupById: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = ({ pickup }) => {
  return { pickup };
};

export default withRouter(
  connect(mapStateToProps, {
    getPickup,
    getPickupById,
    clearPickupNotify,
    getApprovePickupById,
    getRejectPickupById,
    // deleteInvoice,
    // clearInvoiceNotify,
  })(PickupList)
);
