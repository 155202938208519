import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";

import { MetaTags } from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getInvoices, deleteInvoice, clearInvoiceNotify } from "store/actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import InvoiceStatusSummary from "containers/invoice/InvoiceStatusSummary";
import InvoiceTable from "containers/invoice/InvoiceTable";
import { invoiceService } from "services/invoice-service";
import moment from "moment";
import { timestampFileName } from "helpers/utils";
import SendNotificationModal from "pages/ReceiveOrder/SendNotificationModal";
import { orderService } from "services/order-service";
// import { debounce } from "lodash";
import debounce from "lodash.debounce";

const paymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

const InvoiceList = props => {
  const { getInvoices, deleteInvoice, invoice } = props;
  const { items, itemCount, pageCount, loading } = invoice;

  const [id, setId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [term, setTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [invoiceIds, setInvoiceIds] = useState([]);
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState(null);

  const [isExpand, setIsExpand] = useState(false);
  const [status, setStatus] = useState(null);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] =
    useState(false);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState(false);

  useEffect(() => {
    getInvoices({
      page,
      pageSize,
      term,
      paymentStatus,
      myInvoice: false,
      // invoiceIds,
      invoicePaymentStatus,
    });
  }, [page, pageSize, term, invoiceIds, invoicePaymentStatus, paymentStatus]);

  const handleOnSearch = e => {
    const { value } = e.target;
    debouncedFetchData(value);
  };

  const debouncedFetchData = useCallback(
    debounce(value => {
      console.log(value);
      setTerm(value);
    }, 1000),
    []
  );

  const toggleExpand = () => {
    setIsExpand(!isExpand);
  };

  const handleSearch = () => {
    getInvoices({
      page: 1,
      term: term ?? "",
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      myInvoice: false,
      paymentStatus:
        status &&
        status.map(a => {
          return a.value;
        }),
    });
  };

  const handleExportToExcel = () => {
    invoiceService.exportToExcelRequest(
      {
        term: term ?? "",
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null,
        myInvoice: false,
      },
      `${timestampFileName()}_Invoice_List.xlsx`
    );
  };

  const handleSelectChange = (valueType, actionMeta) => {
    setStatus(valueType);
  };

  const handleOnEdit = id => {
    window.open(`/invoice/${id}`, "_blank");
  };

  const handleExportToPdf = id => {
    if (id) {
      invoiceService.exportToPdf(id, `${timestampFileName()}_invoice.pdf`);
    }
  };

  const toggleModalConfirmDelete = () => {
    setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen);
  };

  const handleConfirmDelete = id => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleOnPageChange = page => {
    setCurrentPage(page);
    setPage(page);
  };

  const handleOnPageSizeChange = pageSize => {
    setPage(1);
    setCurrentPage(1);
    setPageSize(pageSize);
  };

  const handleDelete = () => {
    deleteInvoice(id);
    setModalConfirmDeleteIsOpen(false);
  };

  const handleOnNotificationForPayment = invoice => {
    setOrderId(invoice.orderId);
    setModalNotificationIsOpen(true);
  };

  const toggleModalNotification = () => {
    setModalNotificationIsOpen(!modalNotificationIsOpen);
  };

  const handleSendNotification = data => {
    orderService.informToSenderForInvoicePaymentRequest(data).then(() => {
      setModalNotificationIsOpen(false);
    });
  };

  const handleStatusFilter = ({ paymentStatus }) => {
    setInvoicePaymentStatus(null);
    let value = paymentStatus?.value ?? null;
    if (value) {
      setPaymentStatus([value]);
    } else {
      setPaymentStatus(null);
    }
  };

  const handleInvoicePaymentStatusFilter = ({ invoicePaymentStatus }) => {
    setInvoicePaymentStatus(invoicePaymentStatus);
    setInvoiceIds([]);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Invoice List | Retail Logistics</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Invoice List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-2">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={handleOnSearch}
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          selectsStart
                          name="startDate"
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={date => setStartDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Start Date"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          name="endDate"
                          selectsEnd
                          selected={endDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={date => setEndDate(date)}
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="End Date"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <Button
                        type="button"
                        outline
                        color="primary"
                        className="d-lg-none w-100"
                        onClick={handleSearch}
                      >
                        <i className="fas fa-filter" /> Filter
                      </Button>

                      <Button
                        type="button"
                        outline
                        color="primary"
                        className="d-none d-lg-block"
                        onClick={handleSearch}
                      >
                        <i className="fas fa-filter" /> Filter
                      </Button>
                    </Col>
                    <Col md={4}>
                      <Row>
                        <Col md={11}>
                          <div className="text-sm-end">
                            <Button
                              type="button"
                              color="primary"
                              outline
                              size="md"
                              onClick={handleExportToExcel}
                            >
                              <i className="fas fa-file-excel" /> Export to
                              Excel
                            </Button>
                          </div>
                        </Col>
                        <Col md={1}>
                          <div onClick={toggleExpand}>
                            <span className="font-size-24 text-primary me-2">
                              <i
                                className={
                                  isExpand
                                    ? "fas fa-angle-up"
                                    : "fas fa-angle-down"
                                }
                              ></i>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={isExpand}>
                  <CardBody className="pb-1">
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          <Select
                            name="status"
                            value={status}
                            onChange={handleSelectChange}
                            options={paymentStatusOptions}
                            classNamePrefix="select2-selection"
                            placeholder="Payment Status"
                            isClearable
                            isMulti
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col lg="12">
              {items && (
                <>
                  <InvoiceStatusSummary
                    item={invoice}
                    onStatusFilter={handleStatusFilter}
                    onInvoicePaymentStatusFilter={
                      handleInvoicePaymentStatusFilter
                    }
                  />
                  <InvoiceTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    myInvoice={false}
                    onEdit={handleOnEdit}
                    onExportToPdf={handleExportToPdf}
                    onConfirmDelete={handleConfirmDelete}
                    onChangePage={handleOnPageChange}
                    onPageSizeChange={handleOnPageSizeChange}
                    onNotificationForPayment={handleOnNotificationForPayment}
                  />
                  <ConfirmDeleteModal
                    title="Confirm Delete"
                    isOpen={modalConfirmDeleteIsOpen}
                    toggle={toggleModalConfirmDelete}
                    onSubmit={handleDelete}
                  />
                  {orderId && (
                    <SendNotificationModal
                      orderId={orderId}
                      isOpen={modalNotificationIsOpen}
                      toggle={toggleModalNotification}
                      onSubmit={handleSendNotification}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
        <h5></h5>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ invoice }) => {
  return { invoice };
};

InvoiceList.propTypes = {
  message: PropTypes.object,
  invoice: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getInvoices: PropTypes.func,
  deleteInvoice: PropTypes.func,
  history: PropTypes.object,
  clearInvoiceNotify: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, {
    getInvoices,
    deleteInvoice,
    clearInvoiceNotify,
  })(InvoiceList)
);
