// Sale Order
const order = {
  enable: "Order.Enable",
  enableOnMobile: "Order.EnableOnMobile",
  read: "Order.Read",
  write: "Order.Write",
  delete: "Order.Delete",
  export: "Order.Export",
  generateInvoice: "OrderGenerateInvoice.Enable",
  changeDeliveryStatus: "OrderChangeDeliveryStatus.Enable",
};

// Transport
const receiveOrder = {
  enable: "ReceiveOrder.Enable",
};

const transport = {
  enable: "Transport.Enable",
  read: "Transport.Read",
  write: "Transport.Write",
  delete: "Transport.Delete",
};

// Delivery order
const pickup = {
  enable: "Pickup.Enable",
};

const deliveryOrder = {
  enable: "DeliveryOrder.Enable",
  read: "DeliveryOrder.Read",
  write: "DeliveryOrder.Write",
  delete: "DeliveryOrder.Delete",
  export: "DeliveryOrder.Export",
};

const deliveryAgency = {
  enable: "DeliveryAgency.Enable",
};

const deliverer = {
  enable: "Deliverer.Enable",
};

// Invoice
const invoice = {
  enable: "Invoice.Enable",
};

const invoiceNote = {
  enable: "InvoiceNote.Enable",
  read: "InvoiceNote.Read",
  write: "InvoiceNote.Write",
  delete: "InvoiceNote.Delete",
};

const invoicePayment = {
  enable: "InvoicePayment.Enable",
  read: "InvoicePayment.Read",
  write: "InvoicePayment.Write",
  delete: "InvoicePayment.Delete",
  export: "InvoicePayment.Export",
};

const paymentHistory = {
  enable: "PaymentHistory.Enable",
};

// Inventory
const unit = {
  enable: "Unit.Enable",
  read: "Unit.Read",
  write: "Unit.Write",
  delete: "Unit.Delete",
};

const warehouse = {
  enable: "Warehouse.Enable",
  read: "Warehouse.Read",
  write: "Warehouse.Write",
  delete: "Warehouse.Delete",
};

const category = {
  enable: "Category.Enable",
  read: "Category.Read",
  write: "Category.Write",
  delete: "Category.Delete",
};

const attribute = {
  enable: "Attribute.Enable",
};

const banner = {
  enable: "Banner.Enable",
  read: "Banner.Read",
  write: "Banner.Write",
  delete: "Banner.Delete",
};

// User Management
const user = {
  enable: "User.Enable",
};

const role = {
  enable: "Role.Enable",
};

const permissionEntry = {
  enable: "Permission.Enable",
};

// Reports
const orderReport = {
  enable: "OrderReport.Enable",
};

const orderVerifyTracking = {
  enable: "OrderVerifyTracking.Enable",
};

const sendOrderReport = {
  enable: "SendOrderReport.Enable",
};

const receiveOrderReport = {
  enable: "ReceiveOrderReport.Enable",
};

const deliveryOrderReport = {
  enable: "DeliveryOrderReport.Enable",
};

const invoiceReport = {
  enable: "InvoiceReport.Enable",
};

// Settings
const setting = {
  enable: "Setting.Enable",
};

const currency = {
  enable: "Currency.Enable",
};

const shippingFee = {
  enable: "ShippingFee.Enable",
};

const deviceInfo = {
  enable: "DeviceInfo.Enable",
};

const feedback = {
  enable: "Feedback.Enable",
};

const alertNotification = {
  enable: "AlertNotification.Enable",
};

const myDashboard = {
  enable: "MyDashboard.Enable",
};

const dashboard = {
  enable: "Dashboard.Enable",
};

const myOrder = {
  enable: "MyOrder.Enable",
};

const myInvoice = {
  enable: "MyInvoice.Enable",
};

const orderNote = {
  enable: "OrderNote.Enable",
  read: "OrderNote.Read",
  write: "OrderNote.Write",
  delete: "OrderNote.Delete",
};

const orderAttachment = {
  enable: "OrderAttachment.Enable",
  write: "OrderAttachment.Write",
  delete: "OrderAttachment.Delete",
};

export const permission = {
  order,
  orderNote,
  orderAttachment,

  transport,
  receiveOrder,

  pickup,
  deliveryOrder,
  deliveryAgency,
  deliverer,

  invoice,
  invoiceNote,
  invoicePayment,
  paymentHistory,

  unit,
  warehouse,
  category,
  attribute,
  banner,

  user,
  role,
  permissionEntry,

  orderReport,
  orderVerifyTracking,
  sendOrderReport,
  receiveOrderReport,
  deliveryOrderReport,
  invoiceReport,

  setting,
  currency,
  shippingFee,
  deviceInfo,
  feedback,
  alertNotification,
  dashboard,
  myDashboard,
  myOrder,
  myInvoice,
};
