import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { NIL } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import {
  transprotDeliveryStatusOptions,
  receiverDeliveryStatusOptions,
} from "helpers/utils";
import moment from "moment";

export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",
      defaultDayOfETA: 20,
      description: "",
      closeTheContainerDate: null,
      transportDate: null,
      status: { key: 0, value: 0, label: "Draft" },
      submitted: false,
    };
  }

  componentDidMount = () => {
    const { item, isReceiver } = this.props;

    if (item) {
      this.setState({
        ...item,
        defaultDayOfETA: item.defaultDayOfETA === 0 ? 20 : item.defaultDayOfETA,
        description: item.description ?? "",
        closeTheContainerDate: item.closeTheContainerDate
          ? moment(item.closeTheContainerDate).toDate()
          : null,
        transportDate: item.transportDate ? new Date(item.transportDate) : null,
        status: isReceiver
          ? receiverDeliveryStatusOptions.find(e => e.value === item.status)
          : transprotDeliveryStatusOptions.find(e => e.value === item.status),
      });
    }
  };

  static getDerivedStateFromProps(props, state) {
    const { item, isReceiver } = props;
    if (item) {
      if (item.id !== state.id) {
        return {
          ...item,
          defaultDayOfETA:
            item.defaultDayOfETA === 0 ? 20 : item.defaultDayOfETA,
          description: item.description ?? "",
          transportDate: item.transportDate
            ? new Date(item.transportDate)
            : null,
          closeTheContainerDate: item.closeTheContainerDate
            ? moment(item.closeTheContainerDate).toDate()
            : null,
          status: isReceiver
            ? receiverDeliveryStatusOptions.find(e => e.value === item.status)
            : transprotDeliveryStatusOptions.find(e => e.value === item.status),
        };
      }
    }
    return null;
  }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  handleSubmit = () => {
    const {
      id,
      title,
      closeTheContainerDate,
      transportDate,
      status,
      description,
      defaultDayOfETA,
    } = this.state;
    const { onSubmit } = this.props;

    this.setState({
      submitted: true,
    });

    let isValid = title && closeTheContainerDate && transportDate && status;

    if (isValid) {
      const data = {
        id,
        title,
        defaultDayOfETA,
        closeTheContainerDate: closeTheContainerDate
          ? moment(closeTheContainerDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
          : null,
        transportDate: moment(transportDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
        description,
        status: status.value,
      };

      onSubmit(data);
    }
  };

  handleDefaultDayOfETAChange = values => {
    const { value } = values;
    this.setState({
      defaultDayOfETA: value,
    });
  };

  render() {
    const {
      submitted,
      title,
      closeTheContainerDate,
      transportDate,
      status,
      description,
      defaultDayOfETA,
    } = this.state;
    const { isReceiver } = this.props;
    let isDisable = !isReceiver && [2, 3].some(e => e === status.value); //2 In Cambodia Warehouse. 3 Completed

    return (
      <Row>
        <Col md={12}>
          <Card className="mb-3">
            <CardBody>
              <Row>
                <Col md={3}>
                  <FormGroup
                    className={submitted && !title ? " is-invalid" : ""}
                  >
                    <Label htmlFor="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      className={submitted && !title ? " is-invalid" : ""}
                      placeholder="Title"
                      value={this.state.title}
                      onChange={this.handleChange}
                      readOnly={isReceiver}
                    />
                  </FormGroup>
                  {submitted && !title && (
                    <div className="invalid-feedback-custom">
                      Title Type is required.
                    </div>
                  )}
                </Col>
                <Col md={3}>
                  <FormGroup
                    className={
                      submitted && !closeTheContainerDate ? " is-invalid" : ""
                    }
                  >
                    <Label>Close the Container Date</Label>
                    <DatePicker
                      id="closeTheContainerDate"
                      name="closeTheContainerDate"
                      className={
                        "form-control" +
                        (submitted && !closeTheContainerDate
                          ? " is-invalid"
                          : "")
                      }
                      selected={this.state.closeTheContainerDate}
                      onChange={date =>
                        this.handleDateChange(date, "closeTheContainerDate")
                      }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Close the Container Date"
                      isClearable
                      readOnly={isReceiver}
                    />
                  </FormGroup>
                  {submitted && !closeTheContainerDate && (
                    <div className="invalid-feedback-custom">
                      Close the container date is required.
                    </div>
                  )}
                </Col>
                <Col md={3}>
                  <FormGroup
                    className={submitted && !transportDate ? " is-invalid" : ""}
                  >
                    <Label>Transport Date</Label>
                    <DatePicker
                      id="transportDate"
                      name="transportDate"
                      className={
                        "form-control" +
                        (submitted && !transportDate ? " is-invalid" : "")
                      }
                      selected={this.state.transportDate}
                      onChange={date =>
                        this.handleDateChange(date, "transportDate")
                      }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Transport Date"
                      isClearable
                      readOnly={isReceiver}
                    />
                  </FormGroup>
                  {submitted && !transportDate && (
                    <div className="invalid-feedback-custom">
                      Transport Date is required.
                    </div>
                  )}
                </Col>
                <Col md={3}>
                  {this.props.showStatus && (
                    <>
                      <FormGroup
                        className={submitted && !status ? " is-invalid" : ""}
                      >
                        <Label>Status</Label>
                        <Select
                          name="status"
                          value={this.state.status}
                          onChange={this.handleSelectChange}
                          options={
                            isReceiver
                              ? receiverDeliveryStatusOptions
                              : transprotDeliveryStatusOptions
                          }
                          classNamePrefix="select2-selection"
                          isClearable
                          isDisabled={isDisable}
                        />
                      </FormGroup>
                      {submitted && !status && (
                        <div className="invalid-feedback-custom">
                          Status is required.
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup
                    className={
                      submitted && !defaultDayOfETA ? " is-invalid" : ""
                    }
                  >
                    <Label htmlFor="title">Default Day of ETA (Day)</Label>
                    <NumberFormat
                      name="defaultDayOfETA"
                      value={defaultDayOfETA}
                      className={
                        "form-control text-end" +
                        (submitted &&
                        (!defaultDayOfETA || defaultDayOfETA === "0")
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="0"
                      readOnly={isReceiver}
                      thousandSeparator={true}
                      fixedDecimalScale={false}
                      onValueChange={values =>
                        this.handleDefaultDayOfETAChange(values)
                      }
                    />
                  </FormGroup>
                  {submitted && !defaultDayOfETA && (
                    <div className="invalid-feedback-custom">
                      Default Day of ETA is required.
                    </div>
                  )}
                </Col>
                <Col md={9}>
                  <FormGroup>
                    <Label htmlFor="title">Description</Label>
                    <Input
                      type="textarea"
                      name="description"
                      rows={3}
                      placeholder="Description"
                      value={description}
                      onChange={this.handleChange}
                      readOnly={isDisable}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="float-end">
                <Button color="primary" onClick={this.handleSubmit}>
                  Submit{" "}
                </Button>{" "}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

Form.propTypes = {
  isReceiver: PropTypes.bool,
  item: PropTypes.object,
  showStatus: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Form;
