import React, { Component } from "react";
import { Badge } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import jwt from "jsonwebtoken";

const USD_TO_WON_RATE = 1100;

export const decodeJwt = token => {
  if (!token) {
    console.log("Cannot decode empty token.");
    return null;
  }
  const decoded = jwt.decode(token);
  // decoded.uniqueId = decoded["uniqueId"];
  // decoded.firstName = decoded["firstName"];
  // decoded.lastName = decoded["lastName"];
  // decoded.email = decoded["email"];
  return decoded;
};

export const getMaxValue = values => {
  return Math.max.apply(Math, values);
};

export const timestampFileName = () => {
  var today = new Date();
  const fileName = `${today.getFullYear()}${
    today.getMonth() + 1
  }${today.getDate()}${today.getHours()}${today.getMinutes()}`;

  return fileName;
};

export const handleDateChange = (obj, date, name) => {
  obj.setState({ [name]: date });
};

export const displayPaymentStatus = order => {
  const { paymentStatus, paymentStatusName } = order;
  let color = "info";

  switch (paymentStatus) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "info";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
  }

  return (
    <Badge color={color}>
      <span>{paymentStatusName}</span>
    </Badge>
  );
};

export const displayAlertNotificationStatus = alertNotification => {
  const { status, statusName } = alertNotification;
  let color = "info";

  switch (status) {
    case 0: // Draft
      color = "info";
      break;
    case 1: // Pending
      color = "warning";
      break;
    case 2: // Completed
      color = "success";
      break;
    case 3: // Closed
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayDeliveryStatus = order => {
  const { deliveryStatus, deliveryStatusName } = order;
  let color = "info";

  switch (deliveryStatus) {
    case 0:
      color = "primary";
      break;
    case 5:
      color = "success";
      break;
    case 6:
      color = "danger";
      break;
    case 7:
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{deliveryStatusName}</span>
    </Badge>
  );
};

export const displayVerifyStatus = order => {
  const { verifiedStatus, verifiedStatusName } = order;
  let color = "info";

  switch (verifiedStatus) {
    case 0:
      color = "warning";
      break;
    case 1:
      color = "success";
      break;
    case 2:
      color = "danger";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{verifiedStatusName}</span>
    </Badge>
  );
};

export const displayNotificationStatus = item => {
  const { status, statusName } = item;
  let color = "info";

  switch (status) {
    case 1:
      color = "success";
      break;
    case 0:
      color = "warning";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayTransportStatus = transport => {
  const { status, statusName } = transport;
  let color = "info";

  switch (status) {
    case 0:
      color = "infor";
    case 1:
      color = "warning";
      break;
    case 2:
      color = "primary";
      break;
    case 3:
      color = "success";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayInvoicePaymentStatus = invoice => {
  const { status, statusName } = invoice;
  let color = "info";

  switch (status) {
    case 0:
      color = "primary";
      break;
    case 1:
      color = "info";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayDeliveryOrderStatus = transport => {
  const { status, statusName } = transport;
  let color = "info";

  switch (status) {
    case 0:
      color = "info";
      break;
    case 1:
      color = "warning";
      break;
    case 2:
      color = "success";
      break;
    case 3:
      color = "danger";
      break;
    case 4:
      color = "dark";
      break;
  }

  return (
    <Badge color={color} className="px-2 py-2 btn-xs btn-outline">
      <span>{statusName}</span>
    </Badge>
  );
};

export const displayPickupPaymentStatus = order => {
  const { pickupPaymentStatus, pickupPaymentStatusName } = order;
  let color = "info";

  switch (pickupPaymentStatus) {
    case 0:
      color = "danger";
      break;
    case 1:
      color = "success";
      break;
  }

  return (
    <>
      <Badge color={color}>
        <span>{pickupPaymentStatusName}</span>
      </Badge>
    </>
  );
};

export const displayRoleBadge = name => {
  return (
    <Badge color={"primary"}>
      <span>{name}</span>
    </Badge>
  );
};

export const displayDeliveryPaymentStatus = order => {
  const { deliveryPaymentStatus, deliveryPaymentStatusName } = order;
  let color = "info";

  switch (deliveryPaymentStatus) {
    case 0:
      color = "danger";
      break;
    case 1:
      color = "success";
      break;
  }

  return (
    <Badge color={color}>
      <span>{deliveryPaymentStatusName}</span>
    </Badge>
  );
};

export const getUserProfile = () => {
  if (localStorage.getItem("token")) {
    const obj = JSON.parse(localStorage.getItem("token"));
    return decodeJwt(obj);
  }
};

export const hasAccess = permission => {
  let permissions = getPermissions();
  if (!permission) {
    return false;
  }
  if (!permissions) {
    console.log("unknown permissions, denying access", permission);
    return false;
  }
  const isGranted = permissions.includes(permission);
  if (!isGranted) {
    // console.log(`This user does not have ${permission} permission`);
  }

  return isGranted;
};

export const getPermissions = () => {
  let permissionsRaw = localStorage.getItem("permission");
  try {
    return permissionsRaw
      ? JSON.parse(permissionsRaw).map(a => {
          return a.systemName;
        })
      : [];
  } catch (err) {
    return [];
  }
};

export const deliveryStatusOptions = [
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
  { key: 3, value: 3, label: "Completed" },
];

export const transprotDeliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse", isDisabled: true },
  { key: 3, value: 3, label: "Completed", isDisabled: true },
];

export const sendOrderDeliveryStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
  { key: 3, value: 3, label: "Completed" },
];

export const deliveryStatusOptions2 = [
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
];

export const receiverDeliveryStatusOptions = [
  { key: 1, value: 1, label: "Shipping To Cambodia" },
  { key: 2, value: 2, label: "In Cambodia Warehouse" },
  { key: 3, value: 3, label: "Completed" },
];

export const convertWONtoUSD = won => {
  return Number(won ?? 0) / USD_TO_WON_RATE;
};

export const convertUSDtoWON = usd => {
  return Number(usd ?? 0) * USD_TO_WON_RATE;
};

export const paymentMethodOptions = [
  { key: 0, value: 0, label: "Cash" },
  { key: 1, value: 1, label: "ABA" },
  { key: 2, value: 2, label: "ACLEDA" },
  { key: 3, value: 3, label: "Kookmin Bank" },
  { key: 4, value: 4, label: "User Attached Payment" },
];

export const invoicePaymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Pending" },
  { key: 2, value: 2, label: "Confirmed" },
  { key: 3, value: 3, label: "Rejected" },
];

export const pickupPaymentStatusOptions = [
  { key: 0, value: 0, label: "Unpaid" },
  { key: 1, value: 1, label: "Paid" },
];

export const deliveryPaymentStatusOptions = [
  { key: 0, value: 0, label: "Unpaid" },
  { key: 1, value: 1, label: "Paid" },
];

export const shippingPaymentStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "Invoiced" },
  { key: 2, value: 2, label: "Paid" },
  { key: 3, value: 3, label: "Unpaid" },
];

export const deliveryOrderStatusOptions = [
  { key: 0, value: 0, label: "Draft" },
  { key: 1, value: 1, label: "In Progress" },
  { key: 2, value: 2, label: "Completed" },
  { key: 3, value: 3, label: "Rejected" },
  { key: 4, value: 4, label: "Closed" },
];

export const toastMessage = message => {
  const { title, text, severity } = message;

  toastr.options = {
    timeOut: 3000,
  };

  switch (severity) {
    case SEVERITY.INFO:
      toastr.info(text, title);
      break;
    case SEVERITY.DANGER:
      toastr.error(text, title);
      break;
    case SEVERITY.SUCCESS:
      toastr.success(text, title);
      break;
    case SEVERITY.WARNING:
      toastr.warning(text, title);
      break;
  }
};

export const SEVERITY = {
  SUCCESS: "SEVERITY_SUCCESS",
  INFO: "SEVERITY_INFO",
  WARNING: "SEVERITY_WARNING",
  DANGER: "SEVERITY_DANGER",
};

export const getDayDifference = (date1, date2) => {
  // To calculate the time difference of two dates
  var differenceInTime = date1.getTime() - date2.getTime();

  // To calculate the no. of days between two dates
  var differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.floor(differenceInDays);
};

export const getDaysOverDeadline = deadline => {
  return getDayDifference(new Date(), deadline);
};

export const getDaysLeftBeforeDeadline = deadline => {
  return getDayDifference(deadline, new Date());
};

export const displayInvoicePayments = item => {
  const {
    countConfirmedPayment,
    countDraftPayment,
    countPendingPayment,
    countRejectedPayment,
  } = item;
  return (
    <div>
      {countDraftPayment > 0 && (
        <Badge className="p-1 me-1" color="info">
          {countDraftPayment} Draft
        </Badge>
      )}
      {countPendingPayment > 0 && (
        <Badge className="p-1 me-1" color="warning">
          {countPendingPayment} Pending
        </Badge>
      )}
      {countConfirmedPayment > 0 && (
        <Badge className="p-1 me-1" color="success">
          {countConfirmedPayment} Confirmed
        </Badge>
      )}
      {countRejectedPayment > 0 && (
        <Badge className="p-1 me-1" color="danger">
          {countRejectedPayment} Rejected
        </Badge>
      )}
    </div>
  );
};
